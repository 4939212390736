import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DataTrapFormLayout, DataTrapUserForm, DataTrapChapter } from "../models/datatrap.models";
import { Observable } from "rxjs";
import { SearchResult, SearchField } from "@daytona/common";

@Injectable()
export class DataTrapService {
    constructor(private readonly http: HttpClient) { }

    private cachedDataTrapFormLayout: DataTrapFormLayout;

    public getDataTrapForm() {
        return new Observable<DataTrapFormLayout>(subscriber => {
            if (this.cachedDataTrapFormLayout) {
                subscriber.next(this.cachedDataTrapFormLayout);
                subscriber.complete();
            } else {
                this.http.get<DataTrapFormLayout>("api/DataTrap/Form").subscribe(c => {
                    this.cachedDataTrapFormLayout = c;
                    subscriber.next(this.cachedDataTrapFormLayout);
                    subscriber.complete();
                }, error => {
                    subscriber.error(error);
                });
            }
        });
    }

    private cachedDataTrapChapters: DataTrapChapter[];

    public getDataTrapChapters() {
        return new Observable<DataTrapChapter[]>(subscriber => {
            if (this.cachedDataTrapChapters) {
                subscriber.next(this.cachedDataTrapChapters);
                subscriber.complete();
            } else {
                this.http.get<DataTrapChapter[]>("api/DataTrap/Chapters").subscribe(c => {
                    this.cachedDataTrapChapters = c;
                    subscriber.next(this.cachedDataTrapChapters);
                    subscriber.complete();
                }, error => {
                    subscriber.error(error);
                });
            }
        });
    }

    public getUserSessions() {
        return this.http.get<DataTrapUserForm[]>("api/DataTrap");
    }

    private cachedUserData: { formId: number, formGuid: string, data: { [key: string]: string } };

    public getUserData(formId: number, formGuid: string): Observable<{ [key: string]: string }> {
        return this.http.get<{ [key: string]: string }>(`api/DataTrap/${formId}/${formGuid}`);
    }

    public getCPAUserData(formId: number, formGuid: string): Observable<{ [key: string]: string }> {
        return this.http.get<{ [key: string]: string }>(`api/DataTrap/Admin/${formId}/${formGuid}`);
    }

    public create(sessionName: string) {
        return this.http.post<DataTrapUserForm>("api/DataTrap", { sessionName: sessionName });
    }

    public copySession(copiedSessionFormId: number, copiedSessionFormGuid: string, newSessionName: string) {
        return this.http.post<DataTrapUserForm>("api/DataTrap/CloneSession", { copyFormId: copiedSessionFormId, copyFormGuid: copiedSessionFormGuid, sessionName: newSessionName });
    }

    public submitForm(formId: number, formGuid: string) {

        return this.http.post(`api/DataTrap/${formId}/${formGuid}/Submit`, {});
    }

    public patch(formId: number, formGuid: string, data: { [key: string]: any }, dirty: { [key: string]: boolean }) {
        const body: { [key: string]: any } = {};

        let send = false;
        for (const key in dirty) {
            if (dirty.hasOwnProperty(key)) {
                if (dirty[key]) {
                    body[key] = data[key];
                    send = true;
                }
            }
        }

        if (send) {
            // We only need to call the patch if there's actually something to patch
            return this.http.patch(`api/DataTrap/${formId}/${formGuid}`, body);
        } else {
            return new Observable((subscriber) => {
                subscriber.next(null);
                subscriber.complete();
            });
        }
    }


    public searchAll(input: SearchField[], sortColumn: string, sortOrder: string, searchIdentifier: string): Observable<SearchResult> {
        const searchFields = {};
        input.filter(c => c.value).forEach(c => searchFields[c.field] = c.value);

        return this.http.post<SearchResult>("api/DataTrap/Admin/SearchUsers", { searchFields: searchFields, sortColumn: sortColumn, sortOrder: sortOrder, searchIdentifier: searchIdentifier });
    }

    public searchCurrentUser(input: SearchField[], sortColumn: string, sortOrder: string, searchIdentifier: string): Observable<SearchResult> {
        const searchFields = {};
        input.filter(c => c.value).forEach(c => searchFields[c.field] = c.value);

        return this.http.post<SearchResult>("api/DataTrap/SearchFiles", { searchFields: searchFields, sortColumn: sortColumn, sortOrder: sortOrder, searchIdentifier: searchIdentifier });
    }
}