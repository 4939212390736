import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Resolve } from "@angular/router";
import { DataTrapFormLayout } from "../models/datatrap.models";
import { ActivationService } from "../services/activation.service";

@Injectable()
export class PageAccessibleResolver implements Resolve<boolean> {

    constructor(private readonly router: Router, private readonly activation: ActivationService) { }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var dataTrapForm: DataTrapFormLayout = route.parent.data["dataTrapForm"];
        var data: { [key: string]: string } = route.parent.data["userData"];
        var formid = route.parent.params["formid"];
        var formguid = route.parent.params["formguid"];
        var page = route.params["page"];

        var activePage = dataTrapForm.pages[page - 1];
        if (!activePage) {
            // they tried to pick a non-existent page so we just kick them back to home
            this.router.navigate(["/"]);
            return false;
        }

        // now we need to check if they're actually allowed to access that page
        var canVisit = this.activation.isPageAccessible(activePage, dataTrapForm, data);
        if (!canVisit) {
            // they weren't able to access the page, so we send them backwards (which will re-evaluate this, eventually stepping them back until they can access it)
            // This really shouldn't happen unless they're doing something wrong, but if they save their URL in a bookmark or something and then later go back and make an
            // additional field required, we wouldn't want to punish them too much for that.
            this.router.navigate(["d", formid, formguid, "p", page-1]);
        }

        return canVisit;
    }
}