import { Component, ChangeDetectionStrategy, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";
import { DataTrapUserForm } from "../models/datatrap.models";

@Component({
    selector: "data-trap-select-session",
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    styles: [`
        .title-section {
            background: #333;
            color: #29a2d7;
            margin-top: -1rem;
        }
        mat-form-field {
            font-size: 0.5rem;
        }
        mat-form-field mat-select {
            font-size: 1rem;
        }
        mat-form-field mat-label {
            font-size: 1rem;
        }
        mat-form-field ::ng-deep label {
            margin-left: .25rem;
            margin-bottom: .25rem;
        }
        mat-form-field.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper ::ng-deep .mat-form-field-label {
            transform: translateY(-2.1em) scale(.75);
        }
        mat-form-field.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float ::ng-deep .mat-form-field-label {
            transform: translateY(-2.1em) scale(.75);
        }
        mat-form-field ::ng-deep .mat-form-field-label-wrapper {
            overflow: visible;
        }
        mat-form-field.mat-form-field-appearance-fill ::ng-deep .mat-select-arrow-wrapper {
            transform: translateY(-10%);
        }
        mat-form-field..mat-form-field-appearance-standard ::ng-deep .mat-select-arrow-wrapper {
            transform: translateY(-10%);
        }
    `],
    template: `
        <form class="container py-3" (ngSubmit)="continueClicked()" #form="ngForm">
            <div class="row">
                <div class="col-12 shadow rounded border p-3 bg-light d-flex flex-column">
                    <div class="row title-section">
                        <h2 class="col-12 mx-0 my-3 font-weight-bold">Session Selection</h2>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">Please select which session you would like to continue working on</div>
                    </div>
                    <div class="row mt-3">
                        <div class="mx-3 p-0 col-lg-3 col-md-4">
                            <mat-form-field class="w-100" appearance="fill">
                                <mat-label>
                                    <span>Session</span>
                                </mat-label>
                                <mat-select [(ngModel)]="selectedSession" class="px-1" name="selectedSession">
                                    <mat-option *ngFor="let option of sessionOptions" [value]="option">{{option.sessionName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <button type="submit" mat-raised-button color="accent" class="mx-3 p-0 col-lg-3 col-md-4 font-weight-bold" [disabled]="form.invalid || !selectedSession">CONTINUE WORKING</button>
                    </div>
                    <div *ngIf="!sessionOptions.length">
                        <div class="text-danger">You do not have any saved sessions. Would you like to <a [routerLink]="['../getting-started']">start a new session</a>?</div>
                    </div>
                    <div class="row mt-3">
                        <mat-error class="col-12">{{error}}</mat-error>
                    </div>
                </div>
            </div>
        </form>
    `,
})
export class SelectSessionPage {

    public sessionOptions: DataTrapUserForm[];
    public selectedSession: DataTrapUserForm;

    public error: string;

    @ViewChild("form", {static: false})
    public form: NgForm;

    constructor(private readonly route: ActivatedRoute, private readonly router: Router) {}

    public ngOnInit() {
        this.route.data.subscribe(d => {
            this.sessionOptions = d["userSessions"];
            this.sessionOptions.forEach(session => {
                if (session.submitDate) {
                    session.sessionName = session.sessionName + " {" + new Date(session.submitDate).toLocaleDateString() + "}";
                }
            });
            this.selectedSession = this.sessionOptions[0];
        });
    }

    public continueClicked() {
        if (this.form.valid) {
            this.router.navigate(["d", this.selectedSession.formId, this.selectedSession.formGuid]);
        }
    }
}