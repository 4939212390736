import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from "@angular/core";
import { DataTrapElement } from "../models/datatrap.models";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { DataTrapMoreInfoDialogComponent } from "./datatrap-more-info-dialog.component";
import { DataTrapCommentsDialogComponent } from "./datatrap-comments-dialog.component";

@Component({
    selector: "div[data-trap-element]",
    changeDetection: ChangeDetectionStrategy.Default,
    preserveWhitespaces: false,
    styles: [`
        button {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            height: 24px;
        }
        button mat-icon {
            color: rgba(0,0,0,.3);
        }
        button:focus mat-icon {
            outline: 5px auto #ccc;
        }
        button:hover mat-icon {
            color: rgba(0,0,0,.5);
        }
        .element-wrapper {
            min-height: 36px;
        }
        .min-0 {
            min-width: 0;
        }
        datatrap-checkbox {
            height: 24px;
        }
        :host-context(.text-right) .dt-text-label {
            width: 100%;
        }
        @media(min-width: 576px) {
            :host-context(.text-sm-right) .dt-text-label {
                width: 100%;
            }
        }
        @media(min-width: 768px) {
            :host-context(.text-md-right) .dt-text-label {
                width: 100%;
            }
        }
        @media(min-width: 992px) {
            :host-context(.text-lg-right) .dt-text-label {
                width: 100%;
            }
        }
        @media(min-width: 1200px) {
            :host-context(.text-xl-right) .dt-text-label {
                width: 100%;
            }
        }
    `],
    template: `
        <div class="w-100 d-flex align-items-center element-wrapper" (keydown)="clearMaskedData()">
            <ng-container [ngSwitch]="elementType"> 
                <div *ngSwitchCase="0" class="text-danger dt-text-label">CONFIGURATION ERROR: Missing element definition</div>
                <div *ngSwitchCase="1" class="d-inline-block dt-text-label">{{dataTrapElement.textLabel | fieldReference:data:formInput | resourceReference:resources | functionReference:functions:data}}</div>
                <datatrap-text-input *ngSwitchCase="2" class="mt-2 d-inline-block min-0 flex-grow-1" [element]="dataTrapElement" [data]="data" [resources]="resources" [functions]="functions"></datatrap-text-input>
                <datatrap-radio-group *ngSwitchCase="3" class="d-inline-block min-0 flex-grow-0" [element]="dataTrapElement" [data]="data" [resources]="resources" [functions]="functions"></datatrap-radio-group>
                <datatrap-select-box *ngSwitchCase="4" class="mt-2 d-block min-0 flex-grow-1" [element]="dataTrapElement" [data]="data" [resources]="resources" [functions]="functions"></datatrap-select-box>
                <datatrap-autocomplete *ngSwitchCase="5" class="mt-2 d-block min-0 flex-grow-1" [element]="dataTrapElement" [data]="data" [resources]="resources" [functions]="functions"></datatrap-autocomplete>
                <datatrap-date *ngSwitchCase="6" class="mt-2 d-block min-0 flex-grow-1" [element]="dataTrapElement" [data]="data" [resources]="resources" [functions]="functions"></datatrap-date>
                <datatrap-checkbox *ngSwitchCase="7" class="my-2 d-block min-0 flex-grow-0" [element]="dataTrapElement" [data]="data" [resources]="resources" [functions]="functions" [defaultValue]="data[dataTrapElement.formInput]"></datatrap-checkbox>
                <datatrap-money-field *ngSwitchCase="8" class="mt-2 d-inline-block min-0 flex-grow-1" [element]="dataTrapElement" [data]="data" [resources]="resources" [functions]="functions"></datatrap-money-field>
                <datatrap-percent-field *ngSwitchCase="9" class="mt-2 d-inline-block min-0 flex-grow-1" [element]="dataTrapElement" [data]="data" [resources]="resources" [functions]="functions"></datatrap-percent-field>
                <datatrap-list-form *ngSwitchCase="10" class="mt-2 d-inline-block min-0 flex-grow-1" [element]="dataTrapElement" [data]="data" [resources]="resources" [functions]="functions" [dirty]="dirty" [form]="form"></datatrap-list-form>
                <datatrap-document-attachment *ngSwitchCase="11" class="mt-2 d-inline-block min-0 flex-grow-1" [element]="dataTrapElement" [data]="data" [resources]="resources" [functions]="functions" [formId]="formId" [formGuid]="formGuid"></datatrap-document-attachment>
                <datatrap-id-input *ngSwitchCase="12" class="w-100 min-0 d-inline-block" [element]="dataTrapElement" [clearedMaskedData]="clearedMaskedData" [data]="data" [resources]="resources" [functions]="functions"></datatrap-id-input>
            </ng-container>
            <span *ngIf="dataTrapElement.moreInfo" class="position-relative ml-1 flex-grow-0 d-flex align-items-center">
                <button (click)="showMoreInfo()" type="button" matTooltip="Click here for more information" matTooltipShowDelay="250" matTooltipPosition="above">
                    <mat-icon class="help-icon">help_outline</mat-icon>
                </button>
            </span>
            <span *ngIf="dataTrapElement.commentInput" class="position-relative ml-1 flex-grow-0 d-flex align-items-center">
                <button (click)="showComments()" type="button" matTooltip="Click here to enter additional questions or comments" matTooltipShowDelay="250" matTooltipPosition="above">
                    <mat-icon *ngIf="!data[commentInput]">chat_bubble_outline</mat-icon>
                    <mat-icon *ngIf="data[commentInput]">chat</mat-icon>
                </button>
            </span>
        </div>
    `,
})
export class DataTrapElementComponent {

    @Input("data-trap-element")
    public dataTrapElement: DataTrapElement;

    @Input("form-input")
    public formInput: string;

    @Input("comment-input")
    public commentInput: string;

    @Input("data")
    public data: { [key: string]: string };

    @Input("dirty")
    public dirty: { [key:string]:boolean };

    @Input("resources")
    public resources: { [key: string]: string };

    @Input("functions")
    public functions: { [key: string]: string };

    @Input("form")
    public form: FormGroup;

    public internalFormId: string;

    @Input("formId")
    public formId: number;

    @Input("formGuid")
    public formGuid: string;

    @Input("cpaRole")
    public cpaRole = false;

    public valueChangedSubscription: Subscription;

    public clearedMaskedData = false;

    static readonly maskChar = "X";
    static readonly unmaskedChar = "*";
    static readonly divider = "-";

    /**
     * 0 - Error
     * 1 - Text Label
     * 2 - Text Input
     * 3 - Radio Group
     * 4 - Select Box
     * 5 - Autocomplete
     * 6 - Date
     * 7 - Checkbox
     * 8 - Money
     * 9 - Percent
     * 10 - ListForm
     * 11 - Document Attachment
	 * 12 - Formatted Id
     */
    public elementType: number;

    constructor(private readonly dialog: MatDialog, private readonly cd: ChangeDetectorRef) { }

    public ngOnInit() {
        this.elementType = 0;

        if (this.formInput) {
            switch (this.dataTrapElement.inputType) {
                case "TextLabel":
                    this.elementType = 1;
                    break;
                case "TextInput":
                    this.elementType = 2;
                    break;
                case "RadioGroup":
                    this.elementType = 3;
                    break;
                case "SelectBox":
                    this.elementType = 4;
                    break;
                case "Autocomplete":
                    this.elementType = 5;
                    break;
                case "Date":
                    this.elementType = 6;
                    break;
                case "Checkbox":
                    this.elementType = 7;
                    break;
                case "Money":
                    this.elementType = 8;
                    break;
                case "Percent":
                    this.elementType = 9;
                    break;
                case "ListForm":
                    this.elementType = 10;
                    break;
                case "DocumentAttachment":
                    this.elementType = 11;
                    break;
                case "FormattedID":
                    this.elementType = 12;
                    break;
            }
        } else if (this.dataTrapElement.textLabel) {
            this.elementType = 1;
        }

        // listFormId is nullable in the configuration, but should have a value for list form elements.
        if (this.elementType === 10 && !this.dataTrapElement.listFormId) {
            this.elementType = 0;
        }

        if (this.formInput) {
            this.internalFormId = `${this.formInput}${Math.random()}`;

            if (this.dataTrapElement.required) {
                this.dataTrapElement.formControl = new FormControl(this.data[this.formInput], [Validators.required]);
            } else {
                this.dataTrapElement.formControl = new FormControl(this.data[this.formInput], []);
            }

            this.form.addControl(this.internalFormId, this.dataTrapElement.formControl);

            this.valueChangedSubscription = this.dataTrapElement.formControl.valueChanges.subscribe(c => {
                if (this.data[this.formInput] !== c) {
                    this.dirty[this.formInput] = true;
                }
                
                this.data[this.formInput] = c;
            });
            
        }
    }

    public ngDoCheck() {
        if (this.formInput) {
            const currentDataValue = this.data[this.formInput];
            const currentFormValue = this.dataTrapElement.formControl.value;

            if (currentDataValue !== currentFormValue) {
                this.dataTrapElement.formControl.setValue(currentDataValue, { onlySelf: false, emitEvent: false, emitViewToModelChange: false, emitModelToViewChange: true});
                this.dataTrapElement.formControl.updateValueAndValidity({onlySelf: false, emitEvent: false});
            }
        }
    }

    public ngOnDestroy() {
        if (this.formInput) {
            this.valueChangedSubscription.unsubscribe();
            this.dataTrapElement.formControl = null;

            if (this.dataTrapElement.mask && !this.cpaRole) {
                // using this.data[this.formInput] associative array makes it easier to get the correct value for list elements
                this.data[this.formInput] = this.applyMask(this.data[this.formInput], this.dataTrapElement.mask);
            }

            if (this.form) {
                this.form.removeControl(this.internalFormId);
            }
        }
    }

    public clearMaskedData() {

        if (!this.clearedMaskedData && this.dataTrapElement.mask && this.dataTrapElement.mask.trim().length > 0) {
            // this is to ensure the field is only cleared the first time it is changed,
            // i.e. when the field has masked data in it
            
            this.dataTrapElement.formControl.reset();

            this.clearedMaskedData = true;
        }
    }

    private applyMask(value: string, mask: string) {
        let newValue = "";
        let c: number;

        if (value) {
            for (c = 0; c < value.length; c++) {
                if (c < mask.length && mask[c] === DataTrapElementComponent.maskChar) {
                    newValue += "*";
                }
                else if (c < mask.length && mask[c] === DataTrapElementComponent.unmaskedChar) {
                    newValue += value[c];
                }
                else if (c < mask.length && mask[c] === value[c]) {
                    newValue += value[c];
                }
                else {
                    newValue += "*";
                }
            }
        }

        return newValue;
    }

    public showMoreInfo() {
        if (this.dataTrapElement.moreInfo) {
            this.dialog.open(DataTrapMoreInfoDialogComponent, {
                width: "768px",
                data: {
                    moreInfo: this.dataTrapElement.moreInfo,
                    data: this.data,
                    resources: this.resources,
                    functions: this.functions
                }
            });
        }

        return false;
    }

    public showComments() {
        if (this.dataTrapElement.commentInput) {
            const dialogRef = this.dialog.open(DataTrapCommentsDialogComponent, {
                width: "768px",
                data: {
                    commentInput: this.commentInput,
                    data: this.data,
                    dirty: this.dirty
                }
            });

            dialogRef.beforeClosed().subscribe(() => {
                this.cd.detectChanges();
            });
        }

        return false;
    }
}