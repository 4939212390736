import { Pipe, PipeTransform } from "@angular/core";

/*
 * Performs the resource reference replacement logic on an input string.
 *
 * The text to be replaced must be in the format {{r:ResourceName}}
 */
@Pipe({name: "resourceReference"})
export class ResourceReferencePipe implements PipeTransform {

    public transform(value: string, resources: any): string {
        let previousResult: string;
        let result = value;

        do {
            previousResult = result;
            result = result.replace(/{{r:([a-zA-Z0-9]+)}}/,
                (sub: string, ...args: any[]) => {
                    const resource = resources[args[0]];
                    if (resource) {
                        return resource;
                    }

                    return sub;
                });
        } while (result !== previousResult)

        return result;
    }
}