import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Location } from "@angular/common";
import { DataTrapFormLayout, DataTrapPage } from "../models/datatrap.models";
import { ActivatedRoute } from "@angular/router";
import { ActivationService } from "../services/activation.service";

@Component({
    selector: "data-trap-form-entry",
    changeDetection: ChangeDetectionStrategy.Default,
    preserveWhitespaces: false,
    template: `
        <data-trap-page 
            [dataTrapForm]="dataTrapForm"
            [dataTrapPage]="activePage"
            [pageIndex]="pageIndex"
            [cssStyles]="cssStyles"
            [data]="data" 
            [resources]="dataTrapForm.resources"
            [functions]="dataTrapForm.functions"
            [formId]="formId"
            [formGuid]="formGuid">
        </data-trap-page>
    `,
})
export class FormMainPage {

    public dataTrapForm: DataTrapFormLayout;

    public activePage: DataTrapPage;

    public data: { [key: string]: string };

    public functions: { [key: string]: string };

    public formId: number;

    public formGuid: string;

    public pageIndex: number;

    public cssStyles: { [key: string]: string };

    constructor(private readonly route: ActivatedRoute, private readonly activation: ActivationService, private readonly location: Location, private readonly cd: ChangeDetectorRef) {}

    public ngOnInit() {
        this.route.parent.data.subscribe(c => {
            this.dataTrapForm = c["dataTrapForm"];
            this.data = c["userData"];
            this.functions = c["formFunctions"];
        });

        this.route.parent.params.subscribe(c => {
            this.formId = c["formid"];
            this.formGuid = c["formguid"];
        });

        this.route.params.subscribe(c => {
            const page = c["page"];
            this.pageIndex = page - 1;
            this.activePage = this.dataTrapForm.pages[this.pageIndex];
            try {
                this.cssStyles = JSON.parse(this.dataTrapForm.pages[this.pageIndex].cssStyles);
            } catch (e) {
                console.error("The styles for this page are improperly formatted.  Ensure they are in the format of key-value pairs.", e);
            }

            // This allows us to convert the page route to the title+page route without adding extra history
            this.location.replaceState(`d/${this.formId}/${this.formGuid}/p/${page}/${this.getSafePageTitle()}`);
            
            this.cd.detectChanges();
        });
    }

    private getSafePageTitle() {
        return this.activePage.pageTitle.replace(/[^\w]/gi, "-").toLowerCase();
    }
}