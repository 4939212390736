import { Component, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";
import { DataTrapUserForm } from "../models/datatrap.models";
import { DataTrapService } from '../services/datatrap.service';
import { LoadingService } from "@daytona/common";
import { HttpErrorResponse } from '@angular/common/http';
import { retryWhen, mergeMap, delay } from "rxjs/operators";
import { throwError, of } from "rxjs";

@Component({
    selector: "data-trap-copy-previous",
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    styles: [`
        .title-section {
            background: #333;
            color: #29a2d7;
            margin-top: -1rem;
        }
        mat-form-field {
            font-size: 0.5rem;
        }
        mat-form-field input {
            font-size: 1rem;
        }
        mat-form-field mat-select {
            font-size: 1rem;
        }
        mat-form-field mat-label {
            font-size: 1rem;
        }
        mat-form-field ::ng-deep label {
            margin-left: .25rem;
            margin-bottom: .25rem;
        }
        mat-form-field.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper ::ng-deep .mat-form-field-label {
            transform: translateY(-2.1em) scale(.75);
        }
        mat-form-field.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float ::ng-deep .mat-form-field-label {
            transform: translateY(-2.1em) scale(.75);
        }
        mat-form-field ::ng-deep .mat-form-field-label-wrapper {
            overflow: visible;
        }
        mat-form-field ::ng-deep .mat-form-field-required-marker {
            font-size: 1rem;
        }
        mat-form-field.mat-form-field-appearance-fill ::ng-deep .mat-select-arrow-wrapper {
            transform: translateY(-10%);
        }
        mat-form-field..mat-form-field-appearance-standard ::ng-deep .mat-select-arrow-wrapper {
            transform: translateY(-10%);
        }
    `],
    template: `
        <form class="container py-3" (ngSubmit)="copyClicked()" #form="ngForm">
            <div class="row">
                <div class="col-12 shadow rounded border p-3 bg-light d-flex flex-column">
                    <div class="row title-section">
                        <h2 class="col-12 mx-0 my-3 font-weight-bold">Session Selection</h2>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">Please select which session you would like to copy data from</div>
                    </div>
                    <div class="row mt-3">
                        <div class="mx-3 p-0 col-lg-3 col-md-4">
                            <mat-form-field class="w-100" appearance="fill">
                                <mat-label>
                                    <span>Session</span>
                                </mat-label>
                                <mat-select [(ngModel)]="selectedSession" class="px-1" name="selectedSession">
                                    <mat-option *ngFor="let option of sessionOptions" [value]="option">{{option.sessionName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">Please enter a new name for this copied session so you can find it again if you need to take a break</div>
                    </div>
                    <div class="row mt-3">
                        <div class="mx-3 p-0 col-lg-3 col-md-4">
                            <mat-form-field class="w-100" appearance="fill">
                                <mat-label>
                                    <span>Session Nickname</span>
                                </mat-label>
                                <input matInput required [(ngModel)]="newSessionNickname" name="newSessionNickname" class="px-1"/>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <button type="submit" mat-raised-button color="accent" class="mx-3 p-0 col-lg-3 col-md-4 font-weight-bold" [disabled]="form.invalid || !selectedSession">COPY SESSION</button>
                    </div>
                    <div *ngIf="!sessionOptions.length">
                        <div class="text-danger">You do not have any saved sessions. Would you like to <a [routerLink]="['../getting-started']">start a new session</a>?</div>
                    </div>
                    <div class="row mt-3">
                        <mat-error class="col-12">{{error}}</mat-error>
                    </div>
                </div>
            </div>
        </form>
    `,
})
export class CopyPreviousSessionPage {

    public sessionOptions: DataTrapUserForm[];
    public selectedSession: DataTrapUserForm;

    public newSessionNickname: string;

    public error: string;

    @ViewChild("form", { static: false })
    public form: NgForm;

    constructor(private readonly route: ActivatedRoute, private readonly router: Router, private readonly datatrapService: DataTrapService, private readonly loadingService: LoadingService, private readonly cd: ChangeDetectorRef) { }

    public ngOnInit() {
        this.route.data.subscribe(d => {
            this.sessionOptions = d["userSessions"];
            this.sessionOptions.forEach(session => {
                if (session.submitDate) {
                    session.sessionName = session.sessionName + " {" + new Date(session.submitDate).toLocaleDateString() + "}";
                }
            });
            this.selectedSession = this.sessionOptions[0];
        });
    }

    public copyClicked() {
        this.error = "";

        if (this.form.valid) {

            const obs = this.datatrapService.copySession(this.selectedSession.formId, this.selectedSession.formGuid, this.newSessionNickname).pipe(
                retryWhen(errors => {
                    let count = 0;
                    return errors.pipe(mergeMap(error => {
                        if (error instanceof HttpErrorResponse) {
                            if (error.status >= 400 && error.status < 500) {
                                return throwError(error);
                            }
                        }

                        // if the error was not in the 400 range, it could be network related, so we retry it a few times to see if it resolves itself before we present the error
                        if (count < 3) {
                            count++;
                            return of(error).pipe(delay(100));
                        } else {
                            return throwError(error);
                        }
                    }));
                })
            );

            this.loadingService.attach(obs).subscribe((form) => {
                this.router.navigate(["d", form.formId, form.formGuid]);
            }, (err) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 409) {
                        this.error = "A session already exists with that name. Please choose a new name or open the existing session";
                    }
                } else {
                    this.error = "An unexpected error was encountered. Please try again.";
                }

                this.cd.detectChanges();
            });
        }
    }
}