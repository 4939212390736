import { Component } from "@angular/core";
import { Router, NavigationStart, NavigationEnd, NavigationCancel, RouterEvent } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LoadingService, SettingService } from "@daytona/common";

@Component({
    selector: "app-root",
    template: `<router-outlet></router-outlet>`
})
export class AppComponent {
    private $destroyed = new Subject();
    private loading: Subject<void>;

    constructor(private readonly router: Router, private readonly loadingService: LoadingService, private readonly settingService: SettingService) {
    }

    public ngOnInit() {
        this.settingService.getTheme().subscribe(response => {
            const bodyElement = document.getElementsByTagName("body")[0];
            bodyElement.classList.add(response.theme);
        });
    }

    public ngAfterViewInit() {
        this.router.events.pipe(takeUntil(this.$destroyed)).subscribe((event: RouterEvent) => {
            if (event instanceof NavigationStart) {
                const currentUrl = this.router.createUrlTree([]).toString();
                const targetUrl = event.url.split("?")[0];
                if (currentUrl !== targetUrl) {
                    if (!this.loading) {
                        this.loading = new Subject();
                        this.loadingService.attach(this.loading)
                            .pipe(takeUntil(this.$destroyed))
                            .subscribe();
                    }
                }
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
                if (this.loading) {
                    this.loading.next();
                    this.loading.complete();
                    this.loading = null;
                }
            }
        });
    }

    public ngOnDestroy() {
        this.$destroyed.next();
        this.$destroyed.complete();
    }
}