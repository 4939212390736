import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Location } from "@angular/common";
import { DataTrapFormLayout, DataTrapPage } from "../models/datatrap.models";
import { ActivatedRoute } from "@angular/router";
import { ActivationService } from "../services/activation.service";

@Component({
    selector: "data-trap-cpa-form-entry",
    changeDetection: ChangeDetectionStrategy.Default,
    preserveWhitespaces: false,
    template: `
        <data-trap-page *ngIf="policies.CPA" 
            [cpaRole]="policies.CPA"
            [dataTrapForm]="dataTrapForm"
            [dataTrapPage]="activePage"
            [pageIndex]="pageIndex"
            [data]="data" 
            [resources]="dataTrapForm.resources"
            [formId]="formId"
            [formGuid]="formGuid">
        </data-trap-page>
    `,
})
export class CPAFormMainPage {

    public dataTrapForm: DataTrapFormLayout;

    public activePage: DataTrapPage;

    public data: {[key: string]: string}

    public formId: number;

    public formGuid: string;

    public pageIndex: number;

    public policies: any;

    constructor(private readonly route: ActivatedRoute, private readonly activation: ActivationService, private readonly location: Location, private readonly cd: ChangeDetectorRef) {}

    public ngOnInit() {
        this.route.parent.data.subscribe(c => {
            this.dataTrapForm = c["dataTrapForm"];
            this.data = c["userData"];
        });

        this.route.parent.data.subscribe(c => {
            this.policies = c["policies"];
        });

        this.route.parent.params.subscribe(c => {
            this.formId = c["formid"];
            this.formGuid = c["formguid"];
        });

        this.route.params.subscribe(c => {
            const page = c["page"];
            this.pageIndex = page - 1;
            this.activePage = this.dataTrapForm.pages[this.pageIndex];

            // This allows us to convert the page route to the title+page route without adding extra history
            this.location.replaceState(`admin/${this.formId}/${this.formGuid}/p/${page}/${this.getSafePageTitle()}`);
            
            this.cd.detectChanges();
        });
    }

    private getSafePageTitle() {
        return this.activePage.pageTitle.replace(/[^\w]/gi, "-").toLowerCase();
    }
}