import { Injectable } from "@angular/core"
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { DataTrapFormLayout } from "../models/datatrap.models";
import { DataTrapService } from "../services/datatrap.service";
import { LoadingService } from "@daytona/common";

@Injectable()
export class DataTrapFormResolver implements Resolve<DataTrapFormLayout>{

    constructor(private readonly dataTrapService: DataTrapService, private readonly loading: LoadingService) { }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DataTrapFormLayout> {
        return this.loading.attach(this.dataTrapService.getDataTrapForm());
    }
}