import { Injectable } from "@angular/core";
import { IActivationCondition, DataTrapPage, DataTrapFormLayout } from "../models/datatrap.models";

@Injectable()
export class ActivationService {
    public isActive(value: IActivationCondition, data: any) {
        return this.getActivationFunction(value, data)() as boolean;
    }

    public isPageAccessible(page: DataTrapPage, form: DataTrapFormLayout, data: any) {
        let canVisit = false;
        if (this.isActive(page, data)) {
            // and make sure nothing before this page had unfilled required fields
            const pages = form.pages.filter(c => c.pageId < page.pageId && this.isActive(c, data));
            const sections = pages.map(c => c.sections).reduce((p, c) => { p.push(...c); return p; }, []).filter(c => this.isActive(c, data));
            const rows = sections.map(c => c.rows).reduce((p, c) => { p.push(...c); return p; }, []).filter(c => this.isActive(c, data));
            const elements = rows.map(c => c.elements).reduce((p, c) => { p.push(...c); return p; }, []).filter(c => c.required && c.formInput && this.isActive(c, data));

            canVisit = elements.every(c => !!data[c.formInput]);
        }

        return canVisit;
    }

    public getActivationFunction(value: IActivationCondition, data: any) {
        if (!value.activation) {
            // if the activation condition is null or empty then it is active
            return () => true;
        }

        const result = new Function("data", 'return function() { return '+ value.activation +' }');
        return result(data);
    }
}