import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatCheckboxModule} from "@angular/material/checkbox";
import { MatDialogModule} from "@angular/material/dialog";
import { MatIconModule} from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterModule, Route } from "@angular/router";
import { AppComponent } from "./app.component";
import { UploadModule } from "@daytona/common";
import { MainComponent } from "./main.component";
import { AuthGuardService, LoginPage, WorkspaceCanActivateGuard, CoreModule, LoginModule, SharedModule, AdminGuard, PolicyResolver, LibraryResolver, UploadSettingsResolver } from "@daytona/common";
import { SearchModule, SearchSettingsResolver, DefaultSearchResolver} from "@daytona/search";
import { LayoutModule } from "@daytona/common";
import { FieldModule } from "@daytona/common";
import { HomePage } from "./DataTrap/pages/home.page";
import { DataTrapFormResolver } from "./DataTrap/resolvers/datatrap-form.resolver";
import { DataTrapService } from "./DataTrap/services/datatrap.service";
import { DataTrapPageComponent } from "./DataTrap/components/datatrap-page.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DataTrapSectionComponent } from "./DataTrap/components/datatrap-section.component";
import { DataTrapRowComponent } from "./DataTrap/components/datatrap-row.component";
import { DataTrapElementComponent } from "./DataTrap/components/datatrap-element.component";
import { FieldReferencePipe } from "./DataTrap/pipe/field-reference.pipe";
import { ResourceReferencePipe } from "./DataTrap/pipe/resource-reference.pipe";
import { FunctionReferencePipe } from "./DataTrap/pipe/function-reference.pipe";
import { ActivationPipe } from "./DataTrap/pipe/activation.pipe";
import { ActivationFunctionPipe } from "./DataTrap/pipe/activation-function.pipe";
import { ActivationService } from "./DataTrap/services/activation.service";
import { TextInputComponent } from "./DataTrap/components/form-elements/textinput.component";
import { RadioGroupComponent } from "./DataTrap/components/form-elements/radiogroup.component";
import { SelectBoxComponent } from "./DataTrap/components/form-elements/selectbox.component";
import { AutocompleteComponent } from "./DataTrap/components/form-elements/autocomplete.component";
import { DateComponent } from "./DataTrap/components/form-elements/date.component";
import { DocumentAttachmentComponent } from "./DataTrap/components/form-elements/document-attachment.component";
import { AttachmentService } from "./DataTrap/services/attachment.service";
import { CheckboxComponent } from "./DataTrap/components/form-elements/checkbox.component";
import { ListFormComponent } from "./DataTrap/components/form-elements/listform.component";
import { DataTrapMoreInfoDialogComponent } from "./DataTrap/components/datatrap-more-info-dialog.component";
import { DataTrapCommentsDialogComponent } from "./DataTrap/components/datatrap-comments-dialog.component";
import { GettingStartedPage } from "./DataTrap/pages/getting-started.page";
import { UserDataResolver } from "./DataTrap/resolvers/user-data.resolver";
import { UserFormResolver } from "./DataTrap/resolvers/user-form.resolver";
import { SelectSessionPage } from "./DataTrap/pages/select-session.page";
import { CopyPreviousSessionPage } from "./DataTrap/pages/copy-previous.page";
import { FormMainPage } from "./DataTrap/pages/form-main.page";
import { FormRouterOutletPage } from "./DataTrap/pages/form-router-outlet.page";
import { PageAccessibleResolver } from "./DataTrap/resolvers/page-accessible.resolver";
import { TableOfContentsPage } from "./DataTrap/pages/table-of-contents.page";
import { DataTrapChapterResolver } from "./DataTrap/resolvers/datatrap-chapter.resolver";
import { MoneyFieldComponent } from "./DataTrap/components/form-elements/moneyfield.component";
import { PercentFieldComponent } from "./DataTrap/components/form-elements/percentfield.component";
import { AlertDialogComponent } from "./DataTrap/components/dialogs/alert-dialog.component";
import { FormattedIdComponent } from "./DataTrap/components/form-elements/formatted-id.component";
import { CPAGridComponent } from "./DataTrap/components/cpa-search-grid.component";
import { CPAHomePage } from "./DataTrap/pages/cpa-home.page";
import { CPASearchScreen } from "./DataTrap/components/cpa-search-screen.component";
import { CPAFormMainPage } from "./DataTrap/pages/cpa-form-main.page";
import { CPAUserDataResolver } from "./DataTrap/resolvers/cpa-user-data.resolver";
import { CPAGuardService } from "./DataTrap/services/cpa-guard.service";
import { CPATableOfContentsPage } from "./DataTrap/pages/cpa-table-of-contents.page";
import { DTAuthenticationService } from "./DataTrap/services/dt-authentication.service";
import { DataTable } from "./DataTrap/components/data-table.component";
import { HeaderRowDef } from "./DataTrap/header-row-def.directive";
import { DataCell } from './DataTrap/data-cell.directive';
import { DataRow } from './DataTrap/data-row.directive';
import { DataRowDef } from './DataTrap/data-row-def.directive';
import { HeaderRow } from './DataTrap/header-row.directive';
import { HeaderCell } from './DataTrap/header-cell.directive';
import { UsernameRecoveryPage } from "@daytona/common";
import { AccountRecoveryPage } from "@daytona/common";
import { OtherRecoveryPage } from "@daytona/common";
import { PasswordRecoveryPage } from "@daytona/common";
import { ClientFileSearchPage } from './DataTrap/pages/client-file-search.page';
import { ClientSearchGridComponent } from './DataTrap/components/client-search-grid.component';
import { DocuViewareModule, DocuViewareService } from '@daytona/common';
import { SearchPage } from '@daytona/search';

const routes: Route[] = [
    { path: "", component: HomePage },
    { path: "getting-started", component: GettingStartedPage },
    { path: "session-selection", component: SelectSessionPage, resolve: { userSessions: UserFormResolver } },
    { path: "copy-previous", component: CopyPreviousSessionPage, resolve: { userSessions: UserFormResolver } },
    {
        path: "cpa-home", component: CPAHomePage,
        resolve: {
            searchSettings: SearchSettingsResolver,
            uploadSettings: UploadSettingsResolver,
            policies: PolicyResolver,
            defaultSearch: DefaultSearchResolver
        }
    },
    {
        path: "DataTrap/Search",
        component: ClientFileSearchPage,
        resolve: {
            searchSettings: SearchSettingsResolver,
            uploadSettings: UploadSettingsResolver,
            policies: PolicyResolver,
            defaultSearch: DefaultSearchResolver,
            libraries: LibraryResolver
        }
    },
    {
        path: "DataTrap/DocSearch",
        component: SearchPage,
        resolve: {
            searchSettings: SearchSettingsResolver,
            uploadSettings: UploadSettingsResolver,
            policies: PolicyResolver,
            defaultSearch: DefaultSearchResolver,
            libraries: LibraryResolver
        }
    },
    {
        path: "view",
        component: MainComponent,
        canActivate: [AuthGuardService, WorkspaceCanActivateGuard],
        data: { workspace: "DataTrap" },
        resolve: { policies: PolicyResolver, libraries: LibraryResolver },
        children: [
            {
                path: "",
                loadChildren: () => import('@daytona/preview').then(m => m.PreviewRoutingModule)
            }
        ]
    },
    {
        path: "admin/:formid/:formguid",
        component: FormRouterOutletPage,
        canActivate: [CPAGuardService],
        resolve: {
            policies: PolicyResolver,
            dataTrapForm: DataTrapFormResolver,
            userData: CPAUserDataResolver
        },
        children: [
            { path: "table-of-contents", component: CPATableOfContentsPage, resolve: { chapters: DataTrapChapterResolver } },
            { path: "p/:page", component: CPAFormMainPage, resolve: { pageAccessible: PageAccessibleResolver } },
            { path: "p/:page/:title", component: CPAFormMainPage, resolve: { pageAccessible: PageAccessibleResolver } },
            { path: "**", redirectTo: "table-of-contents" }
        ]
    },
    {
        path: "d/:formid/:formguid", 
        component: FormRouterOutletPage,
        resolve: {
            policies: PolicyResolver,
            dataTrapForm: DataTrapFormResolver, 
            userData: UserDataResolver
        },
         children: [
             { path: "table-of-contents", component: TableOfContentsPage, resolve: { chapters: DataTrapChapterResolver } },
             { path: "p/:page", component: FormMainPage, resolve: { pageAccessible: PageAccessibleResolver } },
             { path: "p/:page/:title", component: FormMainPage, resolve: { pageAccessible: PageAccessibleResolver } },
             { path: "**", redirectTo: "table-of-contents"}
         ]
    }
];

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        CoreModule,
        LoginModule.forRoot(),
        SharedModule.forRoot(),
        BrowserAnimationsModule,
        HttpClientModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        RouterModule.forRoot([
            { path: "login", component: LoginPage },
            { path: "login/account-recovery", component: AccountRecoveryPage },
            { path: "login/account-recovery/username", component: UsernameRecoveryPage },
            { path: "login/account-recovery/password", component: PasswordRecoveryPage },
            { path: "login/account-recovery/other", component: OtherRecoveryPage },
            {
                path: "",
                component: MainComponent,
                canActivate: [AuthGuardService, WorkspaceCanActivateGuard],
                data: { workspace: "DataTrap" },
                resolve: { policies: PolicyResolver, libraries: LibraryResolver },
                children: [
                    {
                        path: "DataTrap/administration",
                        canActivate: [AdminGuard],
                        loadChildren: () => import('@daytona/admin').then(m => m.AdminRoutingModule)
                    },
                    {
                        path: "DataTrap/view",
                        loadChildren: () => import('@daytona/preview').then(m => m.PreviewRoutingModule)
                    },
                    ...routes
                ]
            },
            { path: "**", redirectTo: "" }
        ],
        { enableTracing: true } // <-- set to true for debugging purposes only
        ),
        UploadModule,
        FieldModule,
        LayoutModule,
        DocuViewareModule,
        SearchModule
    ],
    declarations: [
        AppComponent,
        MainComponent,
        HomePage,
        FormRouterOutletPage,
        FormMainPage,
        GettingStartedPage,
        SelectSessionPage,
        CopyPreviousSessionPage,
        DataTrapPageComponent,
        DataTrapSectionComponent,
        DataTrapRowComponent,
        DataTrapElementComponent,
        FieldReferencePipe,
        ResourceReferencePipe,
        FunctionReferencePipe,
        ActivationPipe,
        ActivationFunctionPipe,
        TextInputComponent,
        RadioGroupComponent,
        SelectBoxComponent,
        AutocompleteComponent,
        DateComponent,
        DocumentAttachmentComponent,
        CheckboxComponent,
        MoneyFieldComponent,
        PercentFieldComponent,
        ListFormComponent,
        FormattedIdComponent,
        DataTrapMoreInfoDialogComponent,
        DataTrapCommentsDialogComponent,
        AlertDialogComponent,
		TableOfContentsPage,
        CPAHomePage,
        CPASearchScreen,
        CPAGridComponent,
        CPAFormMainPage,
        CPATableOfContentsPage,
        ClientFileSearchPage,
        ClientSearchGridComponent,
        DataTable,
        DataCell,
        DataRow,
        DataRowDef,
        HeaderCell,
        HeaderRow,
        HeaderRowDef
        
    ],
    providers: [
        DataTrapService,
        CPAGuardService,
        DocuViewareService,
        DataTrapFormResolver,
        ActivationService,
        AttachmentService,
        UserDataResolver,
        UserFormResolver,
        CPAUserDataResolver,
        PageAccessibleResolver,
        DataTrapChapterResolver,
        DTAuthenticationService
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        DataTrapMoreInfoDialogComponent,
        DataTrapCommentsDialogComponent,
        AlertDialogComponent
    ]
})
export class AppModule { }
