import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataTrapMoreInfo } from "../models/datatrap.models";

@Component({
    selector: "datatrap-more-info-dialog",
    preserveWhitespaces: false,
    styles: [`
        [mat-dialog-title] {
            margin: 0;
            padding: 0 0 20px;
            border-bottom: 1px solid rgba(0,0,0,.12);
        }
        [mat-dialog-actions] {
            border-top: 1px solid rgba(0,0,0,.12);
            display: block;
        }
        [mat-dialog-content] {
            padding: 25px;
        }
    `],
    template: `
        <div mat-dialog-title class="primary-color">
            {{ moreInfo.title | fieldReference:data | resourceReference:resources | functionReference:functions:data }}
        </div>
        <div mat-dialog-content>
            <div class="container">
                <div class="row">
                    <div class="col-12" [innerHtml]="moreInfo.content | fieldReference:data | resourceReference:resources | functionReference:functions:data"></div>
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-raised-button color="accent" (click)="close()" class="float-right">CLOSE</button>
        </div>
    `
})
export class DataTrapMoreInfoDialogComponent {

    public moreInfo: DataTrapMoreInfo;

    public data: any;
    
    public resources: { [key: string]: string };

    public functions: { [key: string]: string };

    constructor(private readonly dialogRef: MatDialogRef<DataTrapMoreInfoDialogComponent>, @Inject(MAT_DIALOG_DATA) private readonly dialogData: any) {
        this.moreInfo = dialogData.moreInfo;
        this.data = dialogData.data;
        this.resources = dialogData.resources;
        this.functions = dialogData.functions;
    }

    public close() {
        this.dialogRef.close();
    }
}