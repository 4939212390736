import { Injectable } from "@angular/core"
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { DataTrapService } from "../services/datatrap.service";
import { LoadingService } from "@daytona/common";
import { DataTrapUserForm } from "../models/datatrap.models";

@Injectable()
export class UserFormResolver implements Resolve<Observable<DataTrapUserForm[]>>{

    constructor(private readonly dataTrapService: DataTrapService, private readonly loading: LoadingService) { }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.loading.attach(this.dataTrapService.getUserSessions());
    }
}