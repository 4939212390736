import { Component, ChangeDetectionStrategy, Input, HostListener } from "@angular/core";
import { DataTrapPage, DataTrapFormLayout } from "../models/datatrap.models";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { LoadingService } from "@daytona/common";
import { DataTrapService } from "../services/datatrap.service";
import { Router, NavigationStart } from "@angular/router";
import { catchError, tap } from "rxjs/operators";
import { of, Subscription } from "rxjs";
import { AlertDialogComponent } from "./dialogs/alert-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "data-trap-page",
    changeDetection: ChangeDetectionStrategy.Default,
    preserveWhitespaces: false,
    styles: [`
        .background-extend {
            display: flex;
            flex-grow: 1;
            min-height: 100%;
        }
    `],
    template: `
        <div class="background-extend" [ngStyle]="cssStyles">
            <form class="container" [formGroup]="form" #formDirective="ngForm" (ngSubmit)="nextClicked(formDirective)">
                <div class="row">
                    <h1 class="col-12 mt-3 primary-color">{{dataTrapPage.pageTitle | fieldReference:data | resourceReference:resources | functionReference:functions:data}}</h1>
                </div>
                <data-trap-section *ngFor="let section of dataTrapPage.sections" [dataTrapSection]="section" [cpaRole]="cpaRole" [data]="data" [dirty]="dirty" [resources]="resources" [functions]="functions" [form]="form" [formId]="formId" [formGuid]="formGuid"></data-trap-section>
                <div *ngIf="!dataTrapPage.sections || dataTrapPage.sections.length === 0" class="row">
                    <div class="col-12 text-danger">
                        CONFIGURATION ERROR: Page is missing sections
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col-12">
                        <div class="row justify-content-between no-gutters">
                            <div class="order-1">
                                <button mat-raised-button color="accent" [disabled]="!prevActive" (click)="prevClicked()" type="button" class="mr-3 font-weight-bold">BACK</button>
                                <button mat-raised-button color="accent" (click)="tocClicked()" type="button" class="font-weight-bold">TABLE OF CONTENTS</button>
                            </div>
                            <div class="order-11">
                                <mat-error *ngIf="formDirective.submitted && form.invalid" class="d-inline-block">Please fill in all required fields</mat-error>
                                <button mat-raised-button color="accent" *ngIf="nextActive" type="submit" class="ml-3 font-weight-bold">NEXT</button>
                                <button mat-raised-button color="accent" *ngIf="!nextActive" type="button" (click)="submit(formDirective)" class="ml-3 font-weight-bold">SUBMIT</button>         
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    `,
})
export class DataTrapPageComponent {

    @Input("dataTrapForm")
    public dataTrapForm: DataTrapFormLayout;

    @Input("dataTrapPage")
    public dataTrapPage: DataTrapPage;

    @Input("pageIndex")
    public pageIndex: number;

    @Input("data")
    public data: any;

    @Input("resources")
    public resources: { [key: string]: string };

    @Input("functions")
    public functions: { [key: string]: string };

    @Input("formId")
    public formId: number;

    @Input("formGuid")
    public formGuid: string;

    @Input("cpaRole")
    public cpaRole = false;

    public routePrefix = "d";

    public form: FormGroup;

    public dirty: { [key: string]: boolean };

    public prevActive = false;
    public nextActive = false;

    private eventSubscription: Subscription;

    @Input("cssStyles")
    public cssStyles: { [key: string]: string };

    constructor(
        private readonly dialog: MatDialog,
        private readonly loadingService: LoadingService, 
        private readonly dataTrapService: DataTrapService, 
        private readonly router: Router) {}


    public ngOnInit() {

        if (this.cpaRole) {
            // if the user go to a data trap page via CPA route, change the route prefix from 'd' to 'admin'
            this.routePrefix = "admin";
        }

        this.eventSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (!this.canDeactivate()) {
                    // We have an interceptor for handling 401s that redirects to the login page
                    // When this saveData is triggered while unauthenticated, it handles the 401 and tries
                    // to redirect to the login page. Unfortunately, this causes another route change event to be
                    // raised, causing this to be called again, causing another redirect, creating an infinite loop.
                    // The unsubscribe is here to immediately cancel the request after firing it off so that the 401 handler
                    // never gets a chance to handle a response and start the infinite loop in the first place
                    this.saveData().pipe(catchError(() => of(null))).subscribe().unsubscribe();
                }
            }
        });
        
    }

    public ngOnDestroy() {
        this.eventSubscription.unsubscribe();
    }

    public ngOnChanges() {
        this.form = new FormGroup({});
        this.dirty = {};
        this.refreshButtons();
    }
    
    @HostListener('window:beforeunload', ['$event'])
    public unload($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = true;
        }
    }

    public canDeactivate(): boolean {
        return Object.getOwnPropertyNames(this.dirty).length === 0;
    }

    // An assumption is made that the first page and the last page are always going to be active
    private refreshButtons() {
        if (this.dataTrapForm.pages.length - 1 > this.pageIndex) {
            this.nextActive = true;
        } else {
            this.nextActive = false;
        }

        if (this.pageIndex > 0) {
            this.prevActive = true;
        } else {
            this.prevActive = false;
        }
    }

    public nextClicked(formDirective: FormGroupDirective) {
        const controls = this.form.controls;
        for (const key in controls) {
            if (controls.hasOwnProperty(key)) {
                const control = controls[key];
                control.markAsTouched();
                control.markAsDirty();
            }
        }

        if (this.form.valid) {
            this.saveData().subscribe(() => {
                if (this.pageIndex < this.dataTrapForm.pages.length - 1) {
                    let activePageIndex = this.pageIndex;
                    let activePage: DataTrapPage;
                    do {
                        activePageIndex++;
                        activePage = this.dataTrapForm.pages[activePageIndex];
                    } while (!(activePage.activationFunction() || activePageIndex === (this.dataTrapForm.pages.length - 1)))

                    // dirty hack to force the submitted flag back to false on the directive
                    (<any>formDirective).submitted = false;

                    // The url page is 1 based, the page index is zero based, so we need to add 1 to the index to get the correct url
                    this.router.navigate([this.routePrefix, this.formId, this.formGuid, "p", activePageIndex + 1]);
               
                }
            });
        }
    }

    public submit(formDirective: FormGroupDirective) {
        const controls = this.form.controls;
        for (const key in controls) {
            if (controls.hasOwnProperty(key)) {
                const control = controls[key];
                control.markAsTouched();
                control.markAsDirty();
            }
        }

        if (this.form.valid) {
            this.saveData().subscribe(() => {
                 if (this.pageIndex === this.dataTrapForm.pages.length - 1) {
                    const formId = Number(this.formId);

                    // submit form
                     this.loadingService.attach(this.dataTrapService.submitForm(formId, this.formGuid)).subscribe(() => {
                         
                         const dialogRef = this.dialog.open(AlertDialogComponent, {
                             width: "640px",
                             data: {
                                 title: "Form Submitted Successfully",
                                 message: "THANK YOU! Your information will be processed shortly. We will contact you if any additional information is needed. You can expect to hear from us within 3 business days.  We look forward to working with you!  Tax Form for this session has been submitted successfully. You will be now be returned to the home page."
                             }
                         });

                         dialogRef.beforeClosed().subscribe(() => {
                             this.router.navigate([""]);
                         });
                         
                     });
                }
            });
        }
    }

    public prevClicked() {
        if (this.pageIndex > 0) {
            this.saveData().subscribe(() => {
                let activePageIndex = this.pageIndex;
                let activePage: DataTrapPage;
                do {
                    activePageIndex--;
                    activePage = this.dataTrapForm.pages[activePageIndex];
                } while(!(activePage.activationFunction() || activePageIndex === 0))

                // The url page is 1 based, the page index is zero based, so we need to add 1 to the index to get the correct url
                this.router.navigate([this.routePrefix, this.formId, this.formGuid, "p", activePageIndex + 1]);
            });
        }
    }

    public tocClicked() {
        this.saveData().subscribe(() => {
            this.router.navigate([this.routePrefix, this.formId, this.formGuid, "table-of-contents"]);
        });
    }

    private saveData() {
        return this.loadingService.attach(
            this.dataTrapService.patch(this.formId, this.formGuid, this.data, this.dirty).pipe(tap(() => {
                // we just finished saving, so nothing is dirty anymore
                this.dirty = {};
            }))
        );
    }
}