import { Pipe, PipeTransform } from "@angular/core";
import { IActivationCondition } from "../models/datatrap.models";
import { ActivationService } from "../services/activation.service";

@Pipe({name: "activationFunction"})
export class ActivationFunctionPipe implements PipeTransform {

    constructor(private readonly activation: ActivationService) {}

    public transform(value: IActivationCondition, data: any): Function {
        return this.activation.getActivationFunction(value, data);
    }
}