import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { DataTrapElement } from "../../models/datatrap.models";

@Component({
    selector: "datatrap-checkbox",
    changeDetection: ChangeDetectionStrategy.Default,
    preserveWhitespaces: false,
    styles: [`
        mat-checkbox ::ng-deep .mat-checkbox-label {
            overflow: hidden;
            text-overflow: ellipsis;
        }
        mat-checkbox ::ng-deep .mat-checkbox-layout {
            max-width: 100%
        }
    `],
    template: `
        <mat-checkbox class="w-100" [ngModel]="internalValue" (change)="checkboxChanged($event)" color="primary">{{this.dataTrapElement.textLabel | fieldReference:data | resourceReference:resources | functionReference:functions:data}}</mat-checkbox>
    `,
})
export class CheckboxComponent {

    @Input("element")
    public dataTrapElement: DataTrapElement;

    @Input("data")
    public data: any;

    @Input("resources")
    public resources: { [key: string]: string };

    @Input("functions")
    public functions: { [key: string]: string };

    @Input("defaultValue")
    public defaultValue = "false";

    public internalValue: boolean;

    public ngOnInit() {
        if (this.defaultValue) {
            this.internalValue = this.defaultValue == "true";
        }
    }

    public checkboxChanged(value: any) {
        this.internalValue = value.checked;
        this.dataTrapElement.formControl.setValue(value.checked.toString());
    }
}