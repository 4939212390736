import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { DataTrapElement } from "../../models/datatrap.models";

@Component({
    selector: "datatrap-money-field",
    changeDetection: ChangeDetectionStrategy.Default,
    preserveWhitespaces: false,
    styles: [`
        mat-form-field {
            font-size: 0.5rem;
        }
        mat-form-field input {
            font-size: 1rem;
        }
        mat-form-field mat-label {
            font-size: 1rem;
        }
        .dollar-sign {
            font-size: 1.5rem;
        }
        mat-form-field ::ng-deep label {
            margin-left: .25rem;
            margin-bottom: .25rem;
        }
        mat-form-field.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper ::ng-deep .mat-form-field-label {
            transform: translateY(-2.1em) scale(.75);
        }
        mat-form-field.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float ::ng-deep .mat-form-field-label {
            transform: translateY(-2.1em) scale(.75);
        }
        mat-form-field ::ng-deep .mat-form-field-label-wrapper {
            overflow: visible;
        }
        mat-form-field ::ng-deep .mat-form-field-required-marker {
            font-size: 1rem;
        }
    `],
    template: `
        <mat-form-field class="w-100" appearance="fill">
            <mat-label *ngIf="this.dataTrapElement.textLabel">
                <span>{{dataTrapElement.textLabel | fieldReference:data | resourceReference:resources | functionReference:functions:data}}</span>
                <span *ngIf="dataTrapElement.required" aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker">&nbsp;*</span>
            </mat-label>
			<span matPrefix class="dollar-sign">$&nbsp;</span>
            <input matInput type="number" [formControl]="dataTrapElement.formControl" class="px-1"/>
        </mat-form-field>
    `,
})
export class MoneyFieldComponent {

    @Input("element")
    public dataTrapElement: DataTrapElement;

    @Input("data")
    public data: any;

    @Input("resources")
    public resources: { [key: string]: string };

    @Input("functions")
    public functions: { [key: string]: string };

}