import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { DataTrapElement } from "../../models/datatrap.models";

@Component({
    selector: "datatrap-radio-group",
    changeDetection: ChangeDetectionStrategy.Default,
    preserveWhitespaces: false,
    styles: [`
        .invalid {
            color: #f44336;
        }
        .invalid ::ng-deep .mat-radio-outer-circle {
            border-color: #f44336;
        }
        mat-radio-group ::ng-deep .mat-radio-label {
            max-width: 100%;
        }
        mat-radio-group ::ng-deep .mat-radio-label-content {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    `],
    template: `
        <mat-radio-group  class="w-100" [formControl]="dataTrapElement.formControl" class="row">
            <mat-radio-button *ngFor="let option of dataTrapElement.options" [value]="option.optionValue" class="col-12 pl-5" color="primary" [ngClass]="{'invalid': dataTrapElement.formControl.invalid && dataTrapElement.formControl.dirty}">
                {{option.optionDisplay | fieldReference:data | resourceReference:resources | functionReference:functions:data}}
            </mat-radio-button>
        </mat-radio-group>
    `,
})
export class RadioGroupComponent {

    @Input("element")
    public dataTrapElement: DataTrapElement;

    @Input("data")
    public data: any;

    @Input("resources")
    public resources: { [key: string]: string };

    @Input("functions")
    public functions: { [key: string]: string };
}