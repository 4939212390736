import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "datatrap-alert-dialog",
    preserveWhitespaces: false,
    styles: [`
        [mat-dialog-title] {
            margin: 0;
            padding: 0 0 20px;
            border-bottom: 1px solid rgba(0,0,0,.12);
        }
        [mat-dialog-actions] {
            border-top: 1px solid rgba(0,0,0,.12);
            display: block;
        }
        [mat-dialog-content] {
            padding: 25px;
        }
    `],
    template: `
        <div mat-dialog-title class="primary-color">
            {{ title }}
        </div>
        <div mat-dialog-content>
            {{ message }}
        </div>
        <div mat-dialog-actions>
            <button mat-raised-button color="accent" (click)="close()" class="float-right">OK</button>
        </div>
    `
})
export class AlertDialogComponent {

    public title: string;
    public message: string;

    constructor(private readonly dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) private readonly data: any) {
        this.title = data.title;
        this.message = data.message;
    }

    public close() {
        this.dialogRef.close();
    }
}