import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-main",
    styles: [`
        header {
            height: 56px;
        }
        .body {
            position: relative;
            height: calc(100% - 56px);
        }
        :host ::ng-deep three-nine-layout .container-fluid {
            padding-top: 15px;
        }
        a {
            cursor: pointer;
        }
        .spacer {
            flex-grow: 1;
        }
        .navbar {
            background-color: #222;
            box-shadow: 0 1px 8px #333;
        }
    `],
    template: `
        <core-menu>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/']">Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/cpa-home']" [queryParams]="{libraries: librariesStr, workspace: workspaceStr, policies: policiesStr}" *ngIf="policies.CPA">CPA</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/', 'DataTrap', 'Search']" *ngIf="!policies.CPA">File Search</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/', 'DataTrap', 'DocSearch']" *ngIf="policies.CPA">Document Search</a>
                </li>
            </ul>
        </core-menu>
    `
})
export class MainComponent {

    public policies: any;    
    public policiesStr: string;
    public libraries: any;
    public librariesStr: string;
    public workspace: any;
    public workspaceStr: string;

    constructor(private readonly route: ActivatedRoute) {}

    public ngOnInit() {
        this.route.data.subscribe(c => {
            this.policies = c["policies"];
            this.policiesStr = JSON.stringify(this.policies);
            this.libraries = c["libraries"];
            this.librariesStr = JSON.stringify(this.libraries);
            this.workspace = c["workspace"];
            this.workspaceStr = JSON.stringify(this.workspace);
        });
    }
}