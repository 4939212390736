import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "datatrap-comments-dialog",
    preserveWhitespaces: false,
    styles: [`
        [mat-dialog-title] {
            margin: 0;
            padding: 0 0 20px;
            border-bottom: 1px solid rgba(0,0,0,.12);
        }
        [mat-dialog-content] {
            padding-top: 16px;
        }
        [mat-dialog-actions] {
            border-top: 1px solid rgba(0,0,0,.12);
            display: block;
        }
        textarea {
            resize: none;
            border: none;
            overflow: hidden;
        }
        mat-form-field {
            font-size: 0.5rem;
        }
        mat-form-field textarea {
            font-size: 1rem;
        }
    `],
    template: `
        <div mat-dialog-title class="primary-color">
            Comments
        </div>
        <div mat-dialog-content>
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <textarea 
                            [ngModel]="data[commentInput]"
                            (ngModelChange)="modelChange($event)"
                            matInput 
                            class="px-1"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="5"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-raised-button color="accent" (click)="close()" class="float-right">CLOSE</button>
        </div>
    `
})
export class DataTrapCommentsDialogComponent {

    public commentInput: string;

    public data: { [key: string]: string };

    public dirty: { [key: string]: boolean };

    constructor(private readonly dialogRef: MatDialogRef<DataTrapCommentsDialogComponent>, @Inject(MAT_DIALOG_DATA) private readonly dialogData: any) {
        this.commentInput = dialogData.commentInput;
        this.data = dialogData.data;
        this.dirty = dialogData.dirty;
    }

    public modelChange(newValue: string) {
        if (this.data[this.commentInput] !== newValue) {
            this.dirty[this.commentInput] = true;
        }

        this.data[this.commentInput] = newValue;
    }

    public close() {
        this.dialogRef.close();
    }
}