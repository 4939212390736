import { Pipe, PipeTransform } from "@angular/core";

/*
 * Performs the function reference replacement logic on an input string.
 *
 * The text to be replaced must be in the format {{f:FunctionName:Fallback value}}
 */
@Pipe({ name: "functionReference" })
export class FunctionReferencePipe implements PipeTransform {

    public transform(value: string, functions: any, data?: any): string {
        let previousResult: string;
        let result = value;

        do {
            previousResult = result;
            result = result.replace(/{{f:([a-zA-Z0-9]+)}}/,
                (sub: string, ...args: any[]) => {
                    if (args[0] in functions && functions[args[0]] !== undefined) {
                        const functionContent = functions[args[0]];
                        if (functionContent) {
                            const funcResult = new Function("data", functionContent)(data);
                            if (funcResult !== undefined) {
                                return this.escapeHtml(funcResult);
                            }
                            else {
                                return "<<ERROR FUNCTION " + args[0] + " HAS NO RETURN>>";
                            }
                        }
                    }
                    else {
                        return "<<ERROR NO FUNCTION " + args[0] + " FOUND>>";
                    }

                    return sub;
                });
        } while (result !== previousResult)

        return result;
    }

    public escapeHtml(text: string) {
        return text
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }
}