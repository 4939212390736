import { Component, ChangeDetectionStrategy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ActivationService } from "../services/activation.service";
import { DataTrapFormLayout } from "../models/datatrap.models";

@Component({
    selector: "data-trap-main",
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    template: `
        <router-outlet></router-outlet>
    `,
})
export class FormRouterOutletPage {

    constructor(private readonly route: ActivatedRoute, private readonly activation: ActivationService) {}

    public ngOnInit() {
        this.route.data.subscribe(d => {
            const dataTrapForm: DataTrapFormLayout = d["dataTrapForm"];
            const data = d["userData"];

            dataTrapForm.pages.forEach(page => {
                page.activationFunction = this.activation.getActivationFunction(page, data);
            });
        });
    }
}