import { Injectable } from "@angular/core"
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { DataTrapService } from "../services/datatrap.service";
import { LoadingService } from "@daytona/common";

@Injectable()
export class UserDataResolver implements Resolve<Observable<{ [key: string]: string }>>{

    constructor(private readonly dataTrapService: DataTrapService, private readonly loading: LoadingService) { }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ [key: string]: string }> {
        const formId = route.params["formid"];
        const formGuid = route.params["formguid"];
        
        return this.loading.attach(this.dataTrapService.getUserData(formId, formGuid));
        
    }
}