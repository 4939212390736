import { ChangeDetectionStrategy, ChangeDetectorRef, Input, Component, ViewChild, ViewContainerRef, ContentChild, ViewEncapsulation } from "@angular/core";
import { HeaderRowDef } from "../header-row-def.directive";
import { DataRowDef } from "../data-row-def.directive";

@Component({
    selector: "data-table",
    exportAs: "data-table",
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    // ReSharper disable once InvalidValue
    styles: [`
        .data-table {
            display: flex;
            flex-direction: column;
            border: 1px solid rgba(0,0,0,.12);
            overflow: hidden;
            height: 100%;
        }
        .header-row-container {
            border-bottom: 1px solid rgba(0,0,0,.12);
            background: rgba(0,0,0, .05);
            box-sizing: border-box;
        }
        .header-row {
            display: flex;
            padding: 0 24px;
            min-height: 56px;
            align-items: center;
        }
        .header-cell {
            flex-grow: 1;
            flex-basis: 0;
            padding: 0 10px;
            font-size: 14px;
            font-weight: bold;
            color: #673ab7;
            overflow: hidden;
            word-wrap: break-word;
        }
        .scroll-container {
            overflow-y: overlay;
            overflow-x: auto;
            height: 100%;
        }
        .row-container {
            border-bottom: 1px solid rgba(0,0,0,.12);
        }
        .scroll-container .row-container:nth-child(even) {
            background: #FFF;
        }
        .scroll-container .row-container:nth-child(odd) {
            background: #FCFCFC;
        }
        .scroll-container .row-container:hover {
            background: #ffeb9f;
        }
        .data-row {
            display: flex;
            box-sizing: border-box;
            min-height: 30px;
            padding: 0 24px;
            align-items: center;
        }
        .data-cell {
            flex-grow: 1;
            flex-basis: 0;
            padding: 0 10px;
            overflow: hidden;
            word-wrap: break-word;
        }
        .table-loading-overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.15);
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    `],
    template: `
        <div class="data-table">
            <div class="header-row-container width-control" [style.margin-left.px]="-scrollPosition">
            <ng-container *ngTemplateOutlet="headerRowDef.template"></ng-container> 
            </div>
            <div class="scroll-container" (scroll)="onScroll($event)">
                <div class="table-loading-overlay" *ngIf="loading">
                    <mat-spinner></mat-spinner>
                </div>
                <ng-container *ngFor="let row of rows">
                    <div class="row-container width-control">
                        <ng-container *ngTemplateOutlet="dataRowDef.template; context: { $implicit: row }"></ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None
})
export class DataTable {

    public scrollPosition = 0;
    public loading = false;

    @Input("data")
    public rows: any[];

    @ContentChild(HeaderRowDef, { static: true })
    public headerRowDef: HeaderRowDef;

    @ContentChild(DataRowDef, { static: true })
    public dataRowDef: DataRowDef;

    @ViewChild("headerOutlet", { read: ViewContainerRef, static: true })
    public headerOutlet: ViewContainerRef;

    @ViewChild("rowOutlet", { read: ViewContainerRef, static: true })
    public rowOutlet: ViewContainerRef;

    constructor(private readonly cd: ChangeDetectorRef) { }

    public onScroll(event: Event) {
        this.scrollPosition = (<any>(event.srcElement)).scrollLeft;
    }

    public showLoading() {
        this.loading = true;
        this.cd.detectChanges();
    }

    public hideLoading() {
        this.loading = false;
        this.cd.detectChanges();
    }
}