import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SessionResponse } from "../models/authentication.models";

@Injectable()
export class DTAuthenticationService {
    constructor(private readonly http: HttpClient) { }

    public recoverUserName(emailAddress: string) {
        return this.http.post(`/api/DataTrap/Authentication/ForgotUsername`, { emailAddress: emailAddress});
    }

    public forgotPassword(username: string) {
        return this.http.post<SessionResponse>(`/api/DataTrap/Authentication/ForgotPassword`, { username: username});
    }

    public verifyForgotPassword(sessionToken: string, verificationCode: string) {
        return this.http.post(`/api/DataTrap/Authentication/ForgotPassword/Verify`, { sessionToken: sessionToken, verificationCode: verificationCode});
    }

    public changePassword(sessionToken: string, verificationCode: string, newPassword: string) {
        return this.http.post(`/api/DataTrap/Authentication/ChangePassword`, { sessionToken: sessionToken, verificationCode: verificationCode, newPassword: newPassword});
    }
}