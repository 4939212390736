import { Component, ChangeDetectionStrategy, Input, ViewChild, ChangeDetectorRef } from "@angular/core";
import { DataTrapElement, FieldAttachment } from "../../models/datatrap.models";
import { FileChooserComponent, LoadingService } from "@daytona/common";
import { AttachmentService } from "../../services/attachment.service";
import { Observable, of } from "rxjs";
import { mergeMap } from "rxjs/operators";

@Component({
    selector: "datatrap-document-attachment",
    changeDetection: ChangeDetectionStrategy.Default,
    preserveWhitespaces: false,
    styles: [`
        :host ::ng-deep .dropzone {
            min-height: 0;
        }
        .file-list {
            height: 200px;
        }
        .row {
            margin-bottom: 10px;
        }
    `],
    template: `
        <div *ngIf="this.dataTrapElement.textLabel">
            <span>{{dataTrapElement.textLabel | fieldReference:data | resourceReference:resources | functionReference:functions:data}}</span>
        </div>
        <div class="row attachments">
            <div class="col-12">
                <div class="file-list" #tableContainer [scrollTop]="tableContainer.scrollHeight">
                    <data-table #table [data]="attachments">
                        <ng-container *headerRowDef>
                            <div headerRow>
                                <div headerCell="fileName">Name</div>
                            </div>
                        </ng-container>
                        <ng-container *dataRowDef="let row">
                            <div dataRow>
                                <div dataCell="fileName"><a href="./api/DataTrap/Attachments/{{row.fileId}}">{{row.fileName}}</a></div>
                            </div>
                        </ng-container>
                    </data-table>
                </div>
            </div>
        </div>
        <div class="row">
            <daytona-file-chooser #fileChooser (addedFiles)="exUpload()" class="col-12"></daytona-file-chooser>
        </div>
    `,
})
export class DocumentAttachmentComponent {

    @Input("element")
    public dataTrapElement: DataTrapElement;

    @Input("data")
    public data: any;

    @Input("resources")
    public resources: { [key: string]: string };

    @Input("functions")
    public functions: { [key: string]: string };

    @ViewChild("fileChooser", {static:false})
    public fileChooser: FileChooserComponent;

    @ViewChild("table", {static:false})
    public table: any;

    @Input("formId")
    public formId: number;

    @Input("formGuid")
    public formGuid: string;

    public attachments: FieldAttachment[];

    constructor(private readonly loadingService: LoadingService,
        private readonly attachmentService: AttachmentService,
        private readonly cd: ChangeDetectorRef) { }

    public ngOnInit() {
        this.loadAttachments();
    }

    public exUpload() {
        this.loadingService.attach(this.upload()).subscribe();
    }

    public upload(): Observable<boolean> {
        return new Observable((subscriber) => {
            setTimeout(() => {
                const files = this.fileChooser.getFiles();
                if (files.length === 0) {
                    subscriber.next(true);
                    subscriber.complete();
                    return;
                }

                const uploadData: { [key: string]: string } = {};
                uploadData["FormInput"] = `${this.dataTrapElement.formInput}`;

                this.attachmentService.upload(files, this.formId, this.formGuid, uploadData).subscribe(() => {
                    this.refreshAttachments().subscribe(success => {
                        subscriber.next(success);
                        subscriber.complete();
                    }, err => subscriber.error(err));
                }, err => subscriber.error(err));
            }, 10);
        });
    }

    private refreshAttachments(): Observable<boolean> {
        this.fileChooser.clearFiles();
        return this.attachmentService.getAttachments(this.dataTrapElement.formInput, this.formGuid).pipe(mergeMap((value) => {
            this.attachments = value;
            this.cd.detectChanges();
            return of(true);
        }));
    }

    private loadAttachments() {
        this.attachmentService.getAttachments(this.dataTrapElement.formInput, this.formGuid).subscribe((value) => {
            this.attachments = value;
            this.cd.detectChanges();
        });
    }
}