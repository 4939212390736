import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FieldAttachment } from "../models/datatrap.models";
import { of } from "rxjs";
import { ScannerDocument } from "@daytona/common";

@Injectable()
export class AttachmentService {

    constructor(private readonly http: HttpClient) { }

    public getAttachments(formInput: string, formId: string) {
        if (!formInput) {
            return of([] as FieldAttachment[]);
        }
        return this.http.get<FieldAttachment[]>(`api/DataTrap/Attachments?formInput=${formInput}&formId=${formId}`);
    }

    public upload(files: File[], formId: number, formGuid: string, data?: { [key: string]: string }) {
        const input = new FormData();
        const uploadFiles = files.filter(c => !(c instanceof ScannerDocument));
        const scannedFiles = files.filter(c => c instanceof ScannerDocument).map(c => c as unknown as ScannerDocument);
        
        for (const uploadFile of uploadFiles) {
            input.append("file", uploadFile);
        }

        for (const scannedFile of scannedFiles) {
            input.append("scannedFile", scannedFile.name +"|"+scannedFile.controlId);
        }
        
        if (data) {
            input.append("jsonData", JSON.stringify(data));
        }

        return this.http.post(`./api/DataTrap/Attachments/${formId}/${formGuid}/Upload`, input);
    }
}