import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { DataTrapElement } from "../../models/datatrap.models";
import { FormGroup } from "@angular/forms";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: "datatrap-list-form",
    changeDetection: ChangeDetectionStrategy.Default,
    preserveWhitespaces: false,
    styles: [`
        mat-form-field {
            font-size: 0.5rem;
        }
        mat-form-field input {
            font-size: 1rem;
        }
        mat-form-field mat-label {
            font-size: 1rem;
        }
        mat-form-field ::ng-deep label {
            margin-left: .25rem;
            margin-bottom: .25rem;
        }
        mat-form-field.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper ::ng-deep .mat-form-field-label {
            transform: translateY(-2.1em) scale(.75);
        }
        mat-form-field.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float ::ng-deep .mat-form-field-label {
            transform: translateY(-2.1em) scale(.75);
        }
        mat-form-field ::ng-deep .mat-form-field-label-wrapper {
            overflow: visible;
        }
        mat-form-field ::ng-deep .mat-form-field-required-marker {
            font-size: 1rem;
        }
        .divider {
            background-color: #999;
            height: 2px;
        }
    `],
    template: `
        <div *ngIf="this.dataTrapElement.textLabel" class="mb-3">
            {{dataTrapElement.textLabel | fieldReference:data | resourceReference:resources | functionReference:functions:data}}
        </div>
        <div class="border rounded p-3">
            <div class="row" *ngFor="let listRow of listRows | async; let i = index">
                <hr *ngIf="i > 0" class="w-100 m-3 divider"/>
                <ng-container *ngFor="let element of elementClones[i]">
                    <div *ngIf="element | activationFunction:data | activation" 
                        [class]="element.cssClasses" 
                        [data-trap-element]="element" 
                        [form-input]="dataTrapElement.formInput + '_' + element.formInput + '_' + i"
                        [comment-input]="dataTrapElement.formInput + '_' + element.commentInput + '_' + i"
                        [data]="data"
                        [dirty]="dirty" 
                        [resources]="resources" 
                        [form]="form"></div>
                </ng-container>
		    </div>
        </div>
        <div class="ml-1 flex-grow-0 d-flex align-items-center justify-content-end">
            <a href="javascript:undefined" class="ml-3 p-1" (click)="addListFormRow()">Add another</a>
            <a href="javascript:undefined" class="ml-3 p-1" (click)="removeListFormRow()">Remove this entry</a>
        </div>
    `,
})
export class ListFormComponent {

    @Input("element")
    public dataTrapElement: DataTrapElement;

    @Input("data")
    public data: any;

    @Input("dirty")
    public dirty: { [key: string]: boolean };

    @Input("resources")
    public resources: { [key: string]: string };

    @Input("functions")
    public functions: { [key: string]: string };

    @Input("form")
    public form: FormGroup;

    public listRows: BehaviorSubject<any[]>;

    public elementClones: DataTrapElement[][] = [];

    public ngOnInit() {
        if (!this.dataTrapElement.formControl.value) {
            // this is set to 1 here, because if there isn't user data, it should just default to showing 1 row.
            this.dataTrapElement.formControl.setValue(1);
        }

        const rowCount = this.currentRowCount();
        for (let i = 0; i < rowCount; i++) {
            this.elementClones.push(this.dataTrapElement.listFormElements.map(c => Object.assign({}, c)));
        }

        this.listRows = new BehaviorSubject(Array(rowCount));

    }

    public addListFormRow() {
        this.elementClones.push(this.dataTrapElement.listFormElements.map(c => Object.assign({}, c)));
        this.dataTrapElement.formControl.setValue(this.currentRowCount() + 1);
        this.listRows.next(Array(this.currentRowCount()));
    }

    public removeListFormRow() {
        if (this.dataTrapElement.formControl.value > 1) {
            // Clear the values of the row we're going to delete
            const lastRowElements = this.elementClones.pop();
            for (const e of lastRowElements) {
                if (e.formControl) {
                    e.formControl.reset();
                }
            }

            this.dataTrapElement.formControl.setValue(this.currentRowCount() - 1);
            this.listRows.next(Array(this.currentRowCount()));
        }
    }

    public currentRowCount(): number {
        return Number(this.dataTrapElement.formControl.value);
    }
}