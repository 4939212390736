import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { map } from "rxjs/operators";
import { UserService } from '@daytona/common';

@Injectable()
export class CPAGuardService implements CanActivate {

    constructor(private readonly userService: UserService, private readonly router: Router) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userService.getUserRoles().pipe(map(policies => {
            if (policies && policies["CPA"]) {
                return true;
            } else {
                this.router.navigate(["**"]);
                return false;
            }
        }));
    }
}