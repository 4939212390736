import { Input, Directive, ElementRef } from "@angular/core";

@Directive({
    selector: "[dataCell]"
})
export class DataCell {
    @Input("dataCell")
    public cellName: string;

    constructor(private readonly el: ElementRef) { }

    public ngOnInit() {
        this.el.nativeElement.classList.add("data-cell");

        if (this.cellName) {
            this.el.nativeElement.classList.add(`column-${this.cellName}`);
        }
    }
}