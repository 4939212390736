import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { SearchPage } from "@daytona/search";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";

@Component({
    selector: "data-trap-cpa-home",
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    styles: [`
        .title-section {
            background: #333;
            color: #29a2d7;
            margin-top: -1rem;
        }
    `],
    template: `
        <three-nine-layout>
            <div left-panel>
                <daytona-search-form [searchConfiguration]="selectedSearch" (search)="search()">
                    <div search-header>
                        <div>
                            <h2 class="primary-color col-12">Search</h2>
                        </div>
                        <div>
                            <daytona-search-selection
                                [options]="searchSettings"
                                [selectedLibrary]="selectedLibrary"
                                [selectedSearch]="selectedSearch"
                                [defaultSearch]="defaultSearch"
                                (selectedLibraryChange)="selectedLibraryChanged($event)"
                                (selectedSearchChange)="selectedSearchChanged($event)">
                            </daytona-search-selection>
                        </div>
                    </div>
                </daytona-search-form>
            </div>
            <div right-panel class="h-100 position-relative">
                <data-trap-cpa-search-grid #searchGrid (clearSearch)="clearSearch()"></data-trap-cpa-search-grid>
            </div>
        </three-nine-layout>
    `,
})
export class CPAHomePage extends SearchPage {
    public policies: any;

    constructor(private readonly routeEx: ActivatedRoute, private readonly dialogEx: MatDialog, private readonly routerEx: Router, private readonly locationEx: Location) {
        super(routeEx, dialogEx, routerEx, locationEx);
    }
    
    public ngOnInit() {

        this.routeEx.queryParams.subscribe(params => {
            this.policies = JSON.parse(params['policies']);
            this.searchSettings = JSON.parse(params['libraries']);
            this.selectedLibrary = this.searchSettings[0]; 
        });

        super.ngOnInit();
    }

    public clearSearch() {

    }
}