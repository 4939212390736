import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { DataTrapSection } from "../models/datatrap.models";
import { FormGroup } from "@angular/forms";

@Component({
    selector: "data-trap-section",
    changeDetection: ChangeDetectionStrategy.Default,
    preserveWhitespaces: false,
    styles: [`
        .section-title {
            background: #333;
            color: #29a2d7;
            margin-top: -1rem;
        }
    `],
    template: `
        <div class="row no-gutters" *ngIf="dataTrapSection | activationFunction:data | activation">
            <div class="col-12 shadow rounded border p-3 my-3 bg-light">
                <div *ngIf="dataTrapSection.sectionTitle" class="row mb-3 section-title py-3">
                    <h4 class="col-12 m-0 font-weight-bold">{{dataTrapSection.sectionTitle | fieldReference:data | resourceReference:resources | functionReference:functions:data}}</h4>
                </div>
                <data-trap-row *ngFor="let row of dataTrapSection.rows" [dataTrapRow]="row" [data]="data" [cpaRole]="cpaRole" [dirty]="dirty" [resources]="resources" [functions]="functions" [form]="form" [formId]="formId" [formGuid]="formGuid"></data-trap-row>
                <div *ngIf="!dataTrapSection.rows || dataTrapSection.rows.length === 0" class="row">
                    <div class="col-12 text-danger">
                        CONFIGURATION ERROR: Section is missing rows
                    </div>
                </div>
            </div>
        </div>
    `,
})
export class DataTrapSectionComponent {

    @Input("dataTrapSection")
    public dataTrapSection: DataTrapSection;

    @Input("data")
    public data: { [key: string]: string };

    @Input("dirty")
    public dirty: { [key: string]: boolean };

    @Input("resources")
    public resources: { [key: string]: string };

    @Input("functions")
    public functions: { [key: string]: string };

    @Input("cpaRole")
    public cpaRole = false;

    @Input("form")
    public form: FormGroup;

    @Input("formId")
    public formId: number;

    @Input("formGuid")
    public formGuid: string;

    public ngOnChanges() {
        
    }
}