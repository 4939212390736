import { Component, ChangeDetectionStrategy, Input, ViewChildren, QueryList, ElementRef } from "@angular/core";
import { DataTrapElement } from "../../models/datatrap.models";
import { FormControl, Validators } from "@angular/forms";

export interface FormattedData {
    charLength: number;
    placeHolderText: string;
    formControl: FormControl;
}

@Component({
    selector: "datatrap-id-input",
    changeDetection: ChangeDetectionStrategy.Default,
    preserveWhitespaces: false,
    styles: [`
        mat-form-field {
            font-size: 0.5rem;
            min-width: 0;
            flex-grow: 1;
            position: inherit;
            padding-right: 5px;
        }
        mat-form-field input {
            font-size: 1rem;
        }
        mat-form-field mat-label {
            font-size: 1rem;
        }
        mat-form-field ::ng-deep label {
            margin-left: .25rem;
            margin-bottom: .25rem;
        }
        mat-form-field.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper ::ng-deep .mat-form-field-label {
            transform: translateY(-2.1em) scale(.75);
        }
        mat-form-field.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float ::ng-deep .mat-form-field-label {
            transform: translateY(-2.1em) scale(.75);
        }
        mat-form-field ::ng-deep .mat-form-field-label-wrapper {
            overflow: visible;
        }
        mat-form-field ::ng-deep .mat-form-field-required-marker {
            font-size: 1rem;
        }
        mat-form-field ::ng-deep label.mat-form-field-label {
            overflow: visible;
        }
    `],
    template: `
        <span class="d-block text-left">
            <span class="d-flex w-100">
            <mat-form-field appearance="fill" *ngFor="let formattedData of formattedDataArray; let i = index" [attr.data-index]="i" floatLabel="always">
                <mat-label *ngIf="dataTrapElement.textLabel && i === 0" class="d-flex">
                    <span>{{dataTrapElement.textLabel | fieldReference:data | resourceReference:resources | functionReference:functions:data}}</span>
                    <span *ngIf="dataTrapElement.required" aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker">&nbsp;*</span>
                </mat-label>
                <input matInput #multiBoxes [formControl]="formattedData.formControl" [placeholder]="formattedData.placeHolderText" (keydown)="beforeChange($event, multiBoxes)" /> <!-- [attr.maxlength]="formattedData.charLength" /> -->
            </mat-form-field>
           </span>
       </span>
    `,
})
export class FormattedIdComponent {

    @Input("element")
    public dataTrapElement: DataTrapElement;

    @Input("data")
    public data: { [key: string]: string };

    @Input("resources")
    public resources: { [key: string]: string };

    @Input("functions")
    public functions: { [key: string]: string };

    @ViewChildren("multiBoxes")
    public multiBoxes: QueryList<ElementRef>;

    @Input("clearedMaskedData")
    private clearedMaskedData: boolean;

    public static readonly maskChar = "X";
    public static readonly unmaskedChar = "*";
    public static readonly divider = "-";

    public formattedDataArray: FormattedData[] = [];

    public ngOnInit() {


        if (this.dataTrapElement.mask) {

            this.formattedDataArray = [];
            const tempValue = this.dataTrapElement.formControl.value || "";

            const tempArrayValue = tempValue.split(FormattedIdComponent.divider);

            const tempMaskArray = this.dataTrapElement.mask.split(FormattedIdComponent.divider);

            for (let i = 0; i < tempMaskArray.length; i++) {
                let placeHolder = "";

                // dynamically adds a number of "X" to placeHolder based on the length of the section of the mask
                // this is done to to show the user the expected formatting for this field
                // without this loop, the place holder text will show up as X for each text box instead of something like...
                //...XXX XX XXXX
                for (let z = 0; z < tempMaskArray[i].length; z++) {
                    placeHolder += "X";
                }

                const formattedData = { charLength: tempMaskArray[i].length, placeHolderText: placeHolder, formControl: new FormControl(tempArrayValue[i]) };
                if (this.dataTrapElement.required) {
                    formattedData.formControl.setValidators([Validators.required]);
                }
                formattedData.formControl.valueChanges.subscribe(val => {
                    this.dataValuesChanged(val, null);
                });

                this.formattedDataArray.push(formattedData);
            }

        }
        else {

            this.formattedDataArray.push({ charLength: 0, placeHolderText: "", formControl: new FormControl("") });

        }

    }

    public beforeChange(event: KeyboardEvent, thisEl: any) {
        // resets the local form text boxes to be blank if the clearedMaskedData value is not true
        // without this, newly loaded data wouldn't be able to be overwritten
        if (!this.clearedMaskedData) {
            this.formattedDataArray.forEach(c => { c.formControl.setValue("") });
        }

        // getting all of the generated textboxes based on element mask 
        // i.e. mask = "XXX-XX" will generate 2 textboxes
        // i.e. mask = "XXX-XX-XX-XX" will generate 4 textboxes
        const elArray = this.multiBoxes.toArray().map(c => c.nativeElement);

        const arrayIndex = elArray.indexOf(event.currentTarget);

        // getting selected value
        const selectedValue = String(this.formattedDataArray[arrayIndex].formControl.value || "");

        // getting selected max character length
        const selectedMaxChar = Number(this.formattedDataArray[arrayIndex].charLength || 0);

        // defaulting pre-selected value
        let preSelectedValue = "";

        // defaulting pre-selected max character length
        let preSelectedMaxChar = 0;

        // if text box exist...
        // this is for getting the previous text box's value for a later if statement
        if (this.formattedDataArray[arrayIndex - 1]) {

            // ...get pre-selected value
            preSelectedValue = String(this.formattedDataArray[arrayIndex - 1].formControl.value || "");

            // ...get pre-selected max character length
            preSelectedMaxChar = Number(this.formattedDataArray[arrayIndex - 1].charLength || 0);
        }

        // auto-tab backwards for when the user uses the "Backspace" key on an empty text box
        if (event.key === "Backspace" && selectedValue === "" && arrayIndex > 0) {

            this.tabBackwardsBackspace(event, thisEl, arrayIndex - 1, elArray);

            return;
        }

        // auto-tab forward for when the user type in a character on a text box where the max char limit has been reached
        if (event.key.length === 1 && selectedMaxChar === selectedValue.length && arrayIndex < this.formattedDataArray.length - 1) {

            this.tabForwards(event, thisEl, arrayIndex, elArray);

            return;
        }

        // auto-tab backwards for when the user uses a character key on an text box if the previous text box is not full
        if (event.key.length === 1 && preSelectedMaxChar > preSelectedValue.length) {

            this.tabBackwardsCharacter(event, thisEl, arrayIndex, elArray);

            return;
        }
    }


    private tabBackwardsBackspace(event: KeyboardEvent, thisEl: any, arrayIndex: number, elArray: any[]) {
        event.preventDefault();

        thisEl.blur();

        let newIndex = arrayIndex;

        // loops to see if there any not blank textboxes to go back to in backwards 
        for (newIndex; newIndex > 0; newIndex--) {
            const tempVal = this.formattedDataArray[newIndex].formControl.value;
            if (tempVal) {
                break;
            }
        }

        elArray[newIndex].focus();

        // since the user is pressed the 'backspace' key from one text box to another...
        // ...it will need to get the previous text box value...
        let newVal = String(this.formattedDataArray[newIndex].formControl.value || "");

        // ...and remove the last character, then call the "dataValuesChanged()" function
        newVal = newVal.slice(0, newVal.length - 1);

        this.dataValuesChanged(newVal, newIndex);

        return;
    }

    private tabForwards(event: KeyboardEvent, thisEl: any, arrayIndex: number, elArray: any[]) {
        event.preventDefault();

        thisEl.blur();

        elArray[arrayIndex + 1].focus();

        // since the user is pressed a key that prints a character from one text box to another...
        // ...it will need to get the next text box value...
        let newVal = String(this.formattedDataArray[arrayIndex + 1].formControl.value || "");

        const newCharLength = newVal.length;

        // ...and add the key character before the new value
        newVal = event.key + newVal;

        // getting next text box's max character limit
        const newMaxChar = Number(this.formattedDataArray[arrayIndex + 1].charLength);

        // if the new max character limit hasn't been reached, call the "dataValuesChanged()" function
        if (newCharLength < newMaxChar) {
            this.dataValuesChanged(newVal, arrayIndex + 1);
        }

        return;
    }

    private tabBackwardsCharacter(event: KeyboardEvent, thisEl: any, arrayIndex: number, elArray: any[]) {
        event.preventDefault();

        let newIndex = arrayIndex;
        let newVal = String(this.formattedDataArray[newIndex].formControl.value || "") + event.key;

        // loops to see if there any textboxes that haven't maxed out on character length to go back to in backwards 
        for (let i = arrayIndex - 1; i >= 0; i--) {
            const tempMaxChar = Number(this.formattedDataArray[i].charLength || 0);
            const tempValChar = String(this.formattedDataArray[i].formControl.value || "");

            if (tempMaxChar > tempValChar.length) {

                // since the user is pressed a key that prints a character from one text box to another...
                // ...it will need to get the next text box value and add the key character after the new value
                newVal = String(this.formattedDataArray[i].formControl.value || "") + event.key;

                newIndex = i;
            }
        }

        thisEl.blur();

        elArray[newIndex].focus();

        this.dataValuesChanged(newVal, newIndex);

        return;
    }

    public dataValuesChanged(val: any, elIndex: number) {
        if (this.formattedDataArray[elIndex]) {
            this.formattedDataArray[elIndex].formControl.setValue(val);
        }

        // collects all of the text box values and stores them in 'strValue'...
        // ...also makes sure to not store undefined as a string
        const strValue = this.formattedDataArray.map(c => c.formControl.value).filter(Boolean).join("-");

        // this ensures the formatted id field won't emit a partial value
        if (strValue.length === this.dataTrapElement.mask.length) {
            this.dataTrapElement.formControl.setValue(strValue);
            this.formattedDataArray.forEach(c => c.formControl.setErrors(null));
        } else {
            this.dataTrapElement.formControl.setValue("");
            this.formattedDataArray.forEach(c => c.formControl.setErrors({ 'invalid': true }));
        }
    }
}