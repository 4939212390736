import { Component, ChangeDetectionStrategy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DataTrapChapter, DataTrapFormLayout } from "../models/datatrap.models";
import { ActivationService } from "../services/activation.service";

@Component({
    selector: "data-trap-toc",
    changeDetection: ChangeDetectionStrategy.Default,
    preserveWhitespaces: false,
    styles: [`
        .title-section {
            background: #333;
            color: #29a2d7;
            margin-top: -1rem;
        }
        a.disabled {
            pointer-events: none;
            cursor: default;
            color: gray;
            color: rgba(0,0,0,.5);
        }
    `],
    template: `
        <div class="container py-3">
            <div class="row">
                <div class="col-12 shadow rounded border p-3 bg-light d-flex flex-column">
                    <div class="row title-section">
                        <h2 class="col-12 mx-0 my-3 font-weight-bold">Table of Contents</h2>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">Select a section below to continue working</div>
                    </div>
                    <div class="row mt-3 ml-3 d-none d-lg-flex">
                        <div class="col-6">
                            <div *ngFor="let chapter of chapters | slice:0:math.ceil(chapters.length/2)" class="mb-3">
                                <a [routerLink]="chapter.enabled ? ['/admin', formId, formGuid, 'p', chapter.firstPage] : null" [class.disabled]="!chapter.enabled">{{chapter.chapterId}}. {{chapter.chapterName}}</a>
                            </div>
                        </div>
                        <div class="col-6 mb-3">
                            <div *ngFor="let chapter of chapters | slice:math.ceil(chapters.length/2)" class="mb-3">
                                <a [routerLink]="chapter.enabled ? ['/admin', formId, formGuid, 'p', chapter.firstPage] : null" [class.disabled]="!chapter.enabled">{{chapter.chapterId}}. {{chapter.chapterName}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3 ml-3 d-lg-none">
                        <div *ngFor="let chapter of chapters" class="col-12 mb-3">
                            <a [routerLink]="chapter.enabled ? ['/admin', formId, formGuid, 'p', chapter.firstPage] : null" [class.disabled]="!chapter.enabled">{{chapter.chapterId}}. {{chapter.chapterName}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-between">
                <button mat-raised-button color="accent" class="mt-3 font-weight-bold" (click)="homeClicked()">HOME</button>
                <button mat-raised-button color="accent" class="mt-3 font-weight-bold" (click)="continueClicked()">CONTINUE WHERE I LEFT OFF</button>
            </div>
        </div>
    `,
})
export class CPATableOfContentsPage {

    public dataTrapForm: DataTrapFormLayout;
    public data: {[key: string]: string}
    public formId: number;
    public formGuid: string;
    public chapters: DataTrapChapter[];
    public math = Math;

    constructor(private readonly route: ActivatedRoute, private readonly router: Router, private readonly activation: ActivationService) { }

    public ngOnInit() {
        this.route.parent.data.subscribe(c => {
            this.dataTrapForm = c["dataTrapForm"];
            this.data = c["userData"];
        });

        this.route.parent.params.subscribe(c => {
            this.formId = c["formid"];
            this.formGuid = c["formguid"];
        });

        this.route.data.subscribe(d => {
            this.chapters = d["chapters"];

            // set the chapter page so we can generate the link
            this.chapters.forEach(c => {
                c.firstPage = c.pageIds[0];
            });

            // we need to calculate if the chapter is accessible
            this.chapters.forEach(c => {
                c.enabled = false;

                const page = this.dataTrapForm.pages.find(p => p.pageId === c.firstPage);
                if (page) {
                    c.enabled = this.activation.isPageAccessible(page, this.dataTrapForm, this.data);
                }
            });
        });
    }

    public homeClicked() {
        this.router.navigate(["/"]);
    }

    public continueClicked() {
        const enabledChapters = this.chapters.filter(c => c.enabled);
        const lastChapter = enabledChapters[enabledChapters.length - 1];
        this.router.navigate(["admin", this.formId, this.formGuid, "p", lastChapter.firstPage]);
    }
}