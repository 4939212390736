import { Component, ViewChild, Input, TemplateRef, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { PagerOptions, ResultField, SearchConfiguration, TableReloadParams, TableData, DaytonaTableComponent, viewType } from "@daytona/common";
import { DataTable } from "../components/data-table.component";
import { ClientFileSearchGridRow } from "../models/datatrap.models";
import { DataTrapService } from "../services/datatrap.service";
import { Observable } from "rxjs";

@Component({
    selector: "data-trap-client-search-grid",
    preserveWhitespaces: false,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        .clickable-icon {
            color: rgba(255,165,0,.8);
        }
        mat-checkbox {
            color: rgba(0,0,0, .54);
        }
        mat-checkbox:hover {
            color: #673ab7;
        }
        :host ::ng-deep .header-cell {
            min-width: 50px;
            max-width: 180px;
        }
        :host ::ng-deep .header-row {
            padding-left: 33px;
        }
        :host ::ng-deep .data-row {
            padding-left: 33px;
        }
        :host ::ng-deep .data-cell {
            min-width: 50px;
            max-width: 180px;
        }
        :host ::ng-deep .column-Checkbox {
            min-width: 30px;
            max-width: 30px;
        }
        :host ::ng-deep .mat-checkbox-checked.mat-primary .mat-checkbox-background {
            background-color: rgba(103, 58, 183, .6);
        }
        .selected-row {
            background-color: #ffd740;
        }
        .selectable-row {
            margin-left: -25px;
            margin-right: -25px;
            cursor: pointer;
        }
        .selectable-row:hover:not(.selected-row) {
            background: #fff5d3;
        }
        [dataRow] {
            margin-left: 25px;
            margin-right: 25px;
        }
        :host ::ng-deep .scroll-container .row-container:nth-child(even) {
            background: #FFF;
        }
        :host ::ng-deep .scroll-container .row-container:nth-child(odd) {
            background: #FCFCFC;
        }
        :host ::ng-deep .leftsidebutton {
            visibility: hidden;
        }
        .flex-container {
            display: flex;
            flex-flow: column;
            flex-grow: 1;
            border-radius: 3px;
        }
        .grid-container {
            flex-grow: 1;
            position: relative;
        }
        :host ::ng-deep .scroll-container {
            overflow: hidden;
        }
        :host ::ng-deep .header-row {
            min-height: 24px;
        }


    `],
    template: `
        <div class="flex-container fill-container mx-3">
            <div *ngIf="fileId" class="image-viewer-container margin" [ngClass]="{'hide-print': !policies.CanPrintFromDocuVieware}">
                <docuvieware [library]="library" [search]="searchInput?.identifier" [fileId]="fileId" [viewType]="type" (documentChange)="docviewareChanged()"></docuvieware>
            </div>
            <div class="flex-container drop-shadow margin">
                <div class="grid-container">
                    <data-table [data]="data">
                        <ng-container *headerRowDef>
                            <div headerRow matSort (matSortChange)="sortChanged($event)">
                                <div headerCell="FileName" mat-sort-header="FileName">File Name</div>
                                <div headerCell="SessionName" mat-sort-header="SessionName">Session Name</div>
                                <div headerCell="SubmitDate" mat-sort-header="SubmitDate">Submit Date</div>
                                <div headerCell="CreateDate" mat-sort-header="CreateDate">Create Date</div>
                            </div>
                        </ng-container>
                        <div *dataRowDef="let row">
                            <div dataRow>
                                <div style="position: relative; width: 0px">
                                </div>
                                <div dataCell="FileName"><a [routerLink]="['../../view', library, row.FileGUID, row.FileName]" [queryParams]="queryParams">{{(row.FileName !== null) ? row.FileName : " "}}</a></div>
                                <div dataCell="SessionName"><a [routerLink]="['/d', row.FormID, row.FormGUID, 'p', '1']">#{{row.FormID}} - {{row.SessionName}}</a></div>
                                <div dataCell="SubmitDate">{{(row.SubmitDate !== null) ? (row.SubmitDate | date: 'M/d/yyyy') : " " }}</div>
                                <div dataCell="CreateDate">{{(row.CreateDate !== null) ? (row.CreateDate | date: 'M/d/yyyy') : " " }}</div>
                            </div>
                        </div>
                    </data-table>
                </div>
            </div>
        </div>
    `
})

export class ClientSearchGridComponent {
    @ViewChild("titleTemplate", { static: false })
    public titleTemplate: TemplateRef<any>;

    public pagerOptions: PagerOptions = {
        pageSize: 100,
        pageSizeOptions: [20, 50, 100, 250, 500]
    };
    public data: any;

    public type: viewType = "viewNative";
    public library = "FieldAttachments";
    public fileId: any;
    public policies: any;
    public queryParams: { [key: string]: string };

    public columnDefinitions: ResultField[];

    public searchInput: SearchConfiguration;
    private sortColumn: string;
    private sortOrder: string;

    @Input("handleReload")
    public handleReload: (params: TableReloadParams) => Observable<TableData<ClientFileSearchGridRow>>;

    @ViewChild("table", { static: false })
    public table: DaytonaTableComponent<any>;

    @ViewChild(DataTable, { static: true })
    public dataTable: DataTable;

    public tableData: Observable<Observable<TableData<ClientFileSearchGridRow>>>;

    public searchOptions: { [key: string]: string };

    constructor(private readonly dataTrapService: DataTrapService, private readonly cd: ChangeDetectorRef) { }

    public setSearchInput(input: SearchConfiguration) {
        this.searchInput = input;
        this.queryParams = { "search": this.searchInput.identifier };
        this.reload();
    }

    public sortChanged(sortEvent: Sort) {
        this.sortColumn = sortEvent.active;
        this.sortOrder = sortEvent.direction;

        this.table.reload();
    }

    private reload() {
        if (this.searchInput) {
            return this.dataTrapService.searchCurrentUser(this.searchInput.searchFields, this.sortColumn, this.sortOrder, this.searchInput.identifier).subscribe((results: any) => {
                this.data = results;
                this.cd.detectChanges();
            });
        }
    }

    public docviewareChanged() {
        console.log('docuvieware changed fileId (client-search-grid.component.ts): ' + this.fileId);
    }
}