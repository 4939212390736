import { Component, ChangeDetectionStrategy } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "data-trap-home",
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    styles: [`
        .title-section {
            background: #333;
            color: #29a2d7;
            margin-top: -1rem;
        }
    `],
    template: `
        <div class="container py-3">
            <div class="row">
                <div class="col-12 shadow rounded border p-3 bg-light d-flex flex-column">
                    <div class="row title-section">
                        <h2 class="col-12 mx-0 my-3 font-weight-bold">eDataTrap Tax Organizer 2022</h2>
                    </div>
                    <div class="row my-3">
                        <div class="col-12">Welcome to eDataTrap Tax Organizer 2022</div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            What would you like to do?
                        </div>
                    </div>
                    <div class="row mt-3">
                        <button mat-raised-button color="accent" (click)="beginClicked()" class="mx-3 col-lg-3 col-md-4 font-weight-bold">START A NEW DATA FILE</button>
                    </div>
                    <div class="row mt-3">
                        <button mat-raised-button color="accent" (click)="continueClicked()" class="mx-3 col-lg-3 col-md-4 font-weight-bold">CONTINUE WHERE I LEFT OFF</button>
                    </div>
                    <div class="row mt-3">
                        <button mat-raised-button color="accent" (click)="copyClicked()" class="mx-3 col-lg-3 col-md-4 font-weight-bold">COPY LAST YEAR'S DATA</button>
                    </div>
                </div>
            </div>
        </div>
    `,
})
export class HomePage {

    constructor(private readonly router: Router) {}

    public beginClicked() {
        this.router.navigate(["/getting-started"]);
    }

    public continueClicked() {
       this.router.navigate(["/session-selection"]);
    }

    public copyClicked() {
        this.router.navigate(["/copy-previous"]);
    }
}