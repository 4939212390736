import { Pipe, PipeTransform } from "@angular/core";

/*
 * Performs the field reference replacement logic on an input string.
 *
 * The text to be replaced must be in the format {{v:FieldName:Fallback value}}
 */
@Pipe({name: "fieldReference"})
export class FieldReferencePipe implements PipeTransform {

    public transform(value: string, data: any, formInput?: string): string {
        let previousResult: string;
        let result = value;

        do {
            previousResult = result;
            result = result.replace(/{{v:([a-zA-Z0-9_]+):(.+?)}}/,
                (sub: string, ...args: any[]) => {
                    let dataReference: string = args[0];
                    if (dataReference.indexOf("_") >= 0) {
                        // if the data reference has an underscore, it means its trying to reference a list form
                        let index = 0;
                        if (formInput) {
                            // If they're also in a list form, we allow them to replace the index with the index of the current form
                            const split = formInput.split("_");
                            if (split.length === 3) {
                                index = +split[2];
                            }
                        }

                        // Then if they use the switch '_idx' we swap with the index of the current row
                        dataReference = dataReference.replace("_idx", `_${index}`);
                    }

                    const fieldData = data[dataReference];
                    if (fieldData) {
                        return fieldData;
                    }

                    return args[1];
                });
        } while (result !== previousResult)

        return result;
    }
}