import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { DataTrapRow } from "../models/datatrap.models";
import { FormGroup } from "@angular/forms";

@Component({
    selector: "data-trap-row",
    changeDetection: ChangeDetectionStrategy.Default,
    preserveWhitespaces: false,
    template: `
        <div class="row align-items-center" *ngIf="dataTrapRow | activationFunction:data | activation">
            <ng-container *ngFor="let element of dataTrapRow.elements">
                <div *ngIf="element | activationFunction:data | activation" [class]="element.cssClasses" [data-trap-element]="element" [cpaRole]="cpaRole" [form-input]="element.formInput" [comment-input]="element.commentInput"  [data]="data" [dirty]="dirty" [resources]="resources" [functions]="functions" [form]="form" [formId]="formId" [formGuid]="formGuid"></div>
            </ng-container>
            <div *ngIf="!dataTrapRow.elements || dataTrapRow.elements.length === 0" class="col-12 text-danger">
                CONFIGURATION ERROR: Row is missing elements
            </div>
        </div>
    `,
})
export class DataTrapRowComponent {

    @Input("dataTrapRow")
    public dataTrapRow: DataTrapRow;

    @Input("data")
    public data: { [key: string]: string };

    @Input("dirty")
    public dirty: { [key: string]: boolean };

    @Input("cpaRole")
    public cpaRole: boolean = false;

    @Input("resources")
    public resources: { [key: string]: string };

    @Input("functions")
    public functions: { [key: string]: string };

    @Input("form")
    public form: FormGroup;

    @Input("formId")
    public formId: number;

    @Input("formGuid")
    public formGuid: string;

    public ngOnChanges() {
        
    }
}